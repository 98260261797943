import { Icon, IconName } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox } from '@mui/material';
import React from 'react';

import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';
import { AutoCompleteValue } from '../Shared/Dropdown/AutoCompleteTypes';
import { Modal } from '../Shared/Modal.component';

const MultiSelectRow: React.FC<{ onSelect: () => void; text: string; selected: boolean }> = ({
  onSelect,
  text,
  selected,
}) => {
  return (
    <Layout.PressableRow align bg="transparent" onClick={onSelect}>
      <Checkbox checked={selected} />
      <OpenSans.Primary size="s-16">{text}</OpenSans.Primary>
    </Layout.PressableRow>
  );
};

const DropDownModal: React.FC<{
  header: string;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  values: AutoCompleteValue[];
  toggle: (key: string) => void;
  selected: string[];
}> = ({ header, showDropdown, setShowDropdown, toggle, selected, values }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const nonSelectedValues = values.filter(
    (value) =>
      value.key &&
      !selected.includes(value.key) &&
      value.value.name.toLowerCase().includes(inputValue.toLowerCase()),
  );
  const selectedValues = values.filter(
    (value) =>
      value.key &&
      selected.includes(value.key) &&
      value.value.name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  if (!showDropdown) {
    return null;
  }

  return (
    <Modal
      showModal={showDropdown}
      setShowModal={(show) => {
        setShowDropdown(show);
      }}
      py={0}
      px={12}
    >
      <Layout.Column
        style={{
          width: '100vw',
          maxWidth: 400,
          height: 400,
        }}
      >
        <Spacer.Vertical size={12} />
        <Layout.Row align="flex-end">
          <Layout.Column grow>
            <LabelTextField
              label={header}
              error={false}
              styles={{
                backgroundColor: theme.colors.inputBackground,
              }}
              active={isFocused}
            >
              <OpenSans.Input
                name="client"
                value={inputValue}
                type="text"
                placeholder={header}
                grow
                style={{ outline: 'none', width: '80%' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValue(event.target.value);
                }}
                autoFocus
                autoComplete="off"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (event.key === 'Enter') {
                    setShowDropdown(false);
                  }
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}
                onBlur={() => {
                  setIsFocused(false);
                }}
              />
            </LabelTextField>
          </Layout.Column>
          <Spacer.Horizontal size={8} />
          <Button.Primary
            onClick={() => {
              setShowDropdown(false);
            }}
            content="Done"
          />
        </Layout.Row>

        <Spacer.Vertical size={4} />
        <Layout.Column style={{ overflowY: 'auto' }} grow>
          {selectedValues.map((value, index) => {
            return (
              <MultiSelectRow
                key={index}
                onSelect={() => {
                  if (value.key) {
                    toggle(value.key);
                  }
                }}
                text={value.value.name}
                selected={true}
              />
            );
          })}
          {nonSelectedValues.map((value, index) => {
            return (
              <MultiSelectRow
                key={index}
                onSelect={() => {
                  if (value.key) {
                    toggle(value.key);
                  }
                }}
                text={value.value.name}
                selected={false}
              />
            );
          })}
        </Layout.Column>
      </Layout.Column>
    </Modal>
  );
};

export const MultiSelectFilter: React.FC<{
  header: string;
  placeholder: string;
  iconicIcon: IconName;
  selected: string[];
  autoCompleteValues: AutoCompleteValue[];
  setSelected: (selected: string[]) => void;
}> = ({ header, placeholder, iconicIcon, selected, autoCompleteValues, setSelected }) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const content = selected.length
    ? selected.reduce((v, c) => {
        const value = autoCompleteValues.find((a) => a.key === c);
        if (value) {
          if (v.length > 0) {
            return `${v}, ${value.value.name}`;
          }
          return value.value.name;
        }
        return v;
      }, '')
    : placeholder;

  return (
    <Layout.Column>
      <OpenSans.Secondary weight="bold-700" size="xs-12">
        {header}
      </OpenSans.Secondary>
      <Spacer.Vertical size={4} />
      <Layout.PressableRow
        border={[1, 'solid', 'inputBorder']}
        bg="inputBackground"
        px
        radius={4}
        py={8}
        onClick={() => setShowDropdown(!showDropdown)}
        align
        style={{ maxWidth: 237, minWidth: 237 }}
      >
        <Icon name={iconicIcon} size={24} />
        <Spacer.Horizontal size={8} />
        {selected.length ? (
          <OpenSans.Primary
            style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            size="s-16"
          >
            {content}
          </OpenSans.Primary>
        ) : (
          <OpenSans.Secondary
            style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            size="s-16"
          >
            {content}
          </OpenSans.Secondary>
        )}

        <Spacer.Horizontal size={16} />
        <Icon name="chevron-down" size={16} />
      </Layout.PressableRow>

      <DropDownModal
        header={header}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        values={autoCompleteValues}
        toggle={(key: string) => {
          if (selected.includes(key)) {
            setSelected(selected.filter((s) => s !== key));
          } else {
            setSelected([...selected, key]);
          }
        }}
        selected={selected}
      />
    </Layout.Column>
  );
};
