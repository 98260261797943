import { getPaddleSubscriptionBillingInfoFunction } from '@easy-expense/auth-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { FEATURES, SubscriptionBilling } from '@easy-expense/utils-shared';
import React from 'react';

import { PlanCard } from './PlanCard.component';
import { Button } from '../../Button.components';
import LoadingSpinner from '../../LoadingSpinner.component';

export const PaddleCustomerInformation: React.FC = () => {
  const [billingInfo, setBillingInfo] = React.useState<SubscriptionBilling | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const features = FEATURES({
    isMileageSubApp: false,
    getTranslation,
  });

  async function getBillingInfo() {
    const billingInfoResp = await getPaddleSubscriptionBillingInfoFunction()();
    setBillingInfo(billingInfoResp.data);
    setIsLoading(false);
  }

  React.useEffect(() => {
    getBillingInfo();
  }, []);

  const MONTH_ABBR = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  function getMonthAbbreviation(monthNum?: number) {
    if (!monthNum) {
      return '';
    }
    return MONTH_ABBR[monthNum - 1];
  }

  function capitalizeFirstLetter(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }

  if (isLoading) {
    return (
      <Layout.Column center grow>
        <LoadingSpinner />
        <Spacer.Vertical size={16} />
        <OpenSans.Primary size="s-16">{getTranslation('Loading...')}</OpenSans.Primary>
      </Layout.Column>
    );
  }

  if (!billingInfo) {
    return (
      <Layout.Row py={32}>
        <Spacer.Horizontal size={24} />

        <PlanCard
          title={getTranslation('Teams Pro')}
          cta={getTranslation('Book a Demo')}
          description={`Get access to all of Easy Expense's most advanced features.`}
          perUser
          price="$12"
          features={features.team.professional}
          inverse
          onPress={() => {
            window.open('https://app.youform.com/forms/jlevp3ti', '_blank');
          }}
        />

        <Spacer.Horizontal size={24} />

        <PlanCard
          title={getTranslation('Teams')}
          cta={getTranslation('Book a Demo')}
          description={getTranslation('Unlimited receipt scanning and expense reports.')}
          price="$8"
          perUser
          features={features.team.team}
          onPress={() => {
            window.open('https://app.youform.com/forms/jlevp3ti', '_blank');
          }}
        />

        <Spacer.Horizontal size={24} />
      </Layout.Row>
    );
  }

  return (
    <Layout.Column py={16} style={{ maxWidth: 700 }}>
      <OpenSans.Primary size="xl-28" weight="bold-700">
        {getTranslation('Billing')}
      </OpenSans.Primary>

      <Layout.Row py={16}>
        <OpenSans.Primary>
          {getTranslation('For questions about billing, contact')}
        </OpenSans.Primary>
        <Spacer.Horizontal />
        <OpenSans.Primary weight="bold-700"> billing@easy-expense.com</OpenSans.Primary>
      </Layout.Row>

      <Layout.Column
        bg="grayXXLight"
        radius={8}
        border={[1, 'solid', 'inputBorder']}
        py={16}
        px={16}
      >
        <Layout.Row border>
          <Layout.Column center>
            <Icon name="card-outline" size={24} iconColor="black" />
          </Layout.Column>
          <Spacer.Horizontal size={18} />
          <Layout.Column grow>
            <Layout.Row justify="space-between" align="center">
              <Layout.Row>
                <OpenSans.Primary>
                  {capitalizeFirstLetter(billingInfo?.paymentMethod?.card?.type ?? '')}
                  {getTranslation(' ending in ')}
                  {billingInfo?.paymentMethod?.card?.last4 ?? 'xxxx'}
                </OpenSans.Primary>
              </Layout.Row>
            </Layout.Row>
            <OpenSans.Secondary size="s-16">
              {getTranslation('Payment method expires ')}
              {getMonthAbbreviation(billingInfo?.paymentMethod?.card?.expiryMonth)}
              {', '}
              {billingInfo?.paymentMethod?.card?.expiryYear ?? ''}
            </OpenSans.Secondary>
          </Layout.Column>
          <Layout.Column center>
            <Button.Primary
              content={getTranslation('Update')}
              onClick={() => {
                window
                  ?.open(
                    billingInfo?.portalSession?.urls.subscriptions.pop()
                      ?.updateSubscriptionPaymentMethod,
                    '_blank',
                  )
                  ?.focus();
              }}
            />
          </Layout.Column>
          <Spacer.Horizontal />
          <Layout.Column center>
            <Button.Destructive
              content={getTranslation('Cancel')}
              onClick={() => {
                window
                  ?.open(
                    billingInfo?.portalSession?.urls.subscriptions.pop()?.cancelSubscription,
                    '_blank',
                  )
                  ?.focus();
              }}
            />
          </Layout.Column>
        </Layout.Row>
      </Layout.Column>

      <Spacer.Vertical size={32} />

      <OpenSans.Primary size="l-20" weight="bold-700" py={8}>
        {getTranslation('Billing Details')}
      </OpenSans.Primary>

      <Layout.Column py={8}>
        <Layout.Row py={8}>
          <OpenSans.Primary size="s-16" style={{ width: 120 }}>
            {getTranslation('Email')}
          </OpenSans.Primary>
          <OpenSans.Secondary size="s-16">{billingInfo?.customer?.email}</OpenSans.Secondary>
        </Layout.Row>

        <Layout.Row py={8}>
          <OpenSans.Primary size="s-16" style={{ width: 120 }}>
            {getTranslation('Country')}
          </OpenSans.Primary>
          <OpenSans.Secondary size="s-16">{billingInfo?.address?.countryCode}</OpenSans.Secondary>
        </Layout.Row>

        <Layout.Row py={8}>
          <OpenSans.Primary size="s-16" style={{ width: 120 }}>
            {getTranslation('Postal')}
          </OpenSans.Primary>
          <OpenSans.Secondary size="s-16">{billingInfo?.address?.postalCode}</OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>

      <Spacer.Horizontal size={32} />
      <Layout.PressableRow
        center
        py={6}
        style={{ width: 220 }}
        bg="grayXXLight"
        radius={16}
        border={[1, 'solid', 'inputBorder']}
        onClick={() => {
          window.open(billingInfo?.portalSession?.urls.general.overview, '_blank')?.focus();
        }}
      >
        <OpenSans.Primary size="s-16">{getTranslation('View Billing History')}</OpenSans.Primary>
        <Spacer.Horizontal size={12} />
        <Icon name="chevron-forward-outline" size={16} iconColor="brandPrimary" />
      </Layout.PressableRow>
    </Layout.Column>
  );
};
