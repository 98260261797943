import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SignupTeams } from '../../components/Onboarding/SignupTeams.component';
import { TeamPlanCode } from '../../lib/Paddle';

export const SignupTeamsPage: React.FC = () => {
  const { planCode } = useParams<{ planCode: TeamPlanCode }>();
  const navigate = useNavigate();

  if (!planCode) {
    navigate('/404');
    return;
  }

  return <SignupTeams planCode={planCode} />;
};
