import { useCachedMembers } from '@easy-expense/data-firestore-client';
import { EntityFilter } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { MRT_ColumnFiltersState } from 'material-react-table';

export const useConvertEntityFilterToColumnFilters = () => {
  const locations = Data.locations.use();
  const vendorValues = Data.vendors.use();
  const members = useCachedMembers();
  const categoryValues = Data.expenseCategories.use();
  const tripClassValues = Data.tripClasses.use();
  const paymentMethodValues = Data.paymentMethods.use();
  const reports = Data.reports.use();

  const convert = (entityFilter: EntityFilter) => {
    const columns: MRT_ColumnFiltersState = [];
    let startDate: string | null = null;
    let endDate: string | null = null;
    let maxTotal: number | null = null;
    let minTotal: number | null = null;

    Object.keys(entityFilter).forEach((key: string) => {
      const filter = entityFilter[key as keyof EntityFilter];
      if (filter && key !== 'setEntityFilter') {
        const vendorName = vendorValues.find((c) => entityFilter.vendors?.includes(c.key))?.value
          .name;
        const locationName = locations.find((l) => entityFilter.locations?.includes(l.key))?.value
          .name;
        const filteredMembers = members.filter((m) => entityFilter.createdBy?.includes(m.key));

        const memberNames = filteredMembers
          ? filteredMembers.map((m) => m.displayName?.toLowerCase())
          : [];
        const reportName = reports.find((m) => entityFilter.reports?.includes(m.key))?.name;
        const categoryName = categoryValues.find((c) => entityFilter.categories?.includes(c.key))
          ?.value.name;
        const tripClassName = tripClassValues.find((c) => entityFilter.tripClasses?.includes(c.key))
          ?.value.name;
        const paymentMethodName = paymentMethodValues.find((c) =>
          entityFilter.paymentMethods?.includes(c.key),
        )?.value.name;

        switch (key) {
          case 'startDate':
            startDate = filter.toString();
            break;
          case 'endDate':
            endDate = filter.toString();
            break;
          case 'vendors':
            if (vendorName) {
              columns.push({ id: 'vendorValueName', value: vendorName });
            }
            break;
          case 'locations':
            if (locationName) {
              columns.push({ id: 'trip', value: locationName });
            }
            break;
          case 'createdBy':
            if (memberNames) {
              columns.push({ id: 'creatorDisplayName', value: memberNames });
            }
            break;
          case 'reports':
            if (reportName) {
              columns.push({ id: 'reports', value: reportName });
            }
            break;
          case 'categories':
            if (categoryName) {
              columns.push({ id: 'categoryValueName', value: [categoryName] });
            }
            break;
          case 'tripClasses':
            if (tripClassName) {
              columns.push({ id: 'tripClassValueName', value: [tripClassName] });
            }
            break;
          case 'paymentMethods':
            if (paymentMethodName) {
              columns.push({ id: 'paymentMethodName', value: [paymentMethodName] });
            }
            break;
          case 'maxTotal':
            maxTotal = filter as number;
            break;
          case 'minTotal':
            minTotal = filter as number;
            break;
        }
      }
    });

    if (maxTotal || minTotal) {
      columns.push({ id: 'total', value: [minTotal ?? '', maxTotal ?? ''] });
    }

    if (startDate || endDate) {
      columns.push({ id: 'date', value: [startDate ?? '', endDate ?? ''] });
    }

    return columns;
  };
  return convert;
};
