import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import React from 'react';

import { BaseAutoCompleteInputParams } from './AutoCompleteTypes';
import { BottomDropDown } from './BottomDropDown';
import { InputIcon } from './InputIcon';
import { TopDropdown } from './TopDropdown';
import { getColor } from './helper';
import { LabelTextField } from '../../LabelTextField.component';

export const BaseAutoCompleteInput: React.FC<
  React.PropsWithChildren<BaseAutoCompleteInputParams>
> = ({
  header,
  value,
  autoCompleteValues,
  onSelect,
  allowCreateNew,
  clearOnNew,
  error,
  placeholder,
  iconicIcon,
  onInputBlur,
  onInputFocus,
}) => {
  const [inputValue, setInputValue] = React.useState(value?.value.name ?? '');

  const [isExisting, setIsExisting] = React.useState(true);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [flipDropdown, setFlipDropdown] = React.useState(false);

  const filteredAutoComplete = autoCompleteValues.filter((v) => {
    return v.value.name.toLowerCase().includes(inputValue.toLowerCase());
  });

  if (!isExisting && allowCreateNew && inputValue.length !== 0) {
    filteredAutoComplete.push({
      value: { iconicIcon: 'add-circle-outline', name: inputValue },
    });
  }

  React.useEffect(() => {
    const checkPosition = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        setFlipDropdown(rect.top > windowHeight / 2 && windowHeight - rect.bottom < 300);
      }
    };
    checkPosition();
    window.addEventListener('scroll', checkPosition, true);
    return () => {
      window.removeEventListener('scroll', checkPosition, true);
    };
  }, []);

  React.useEffect(() => {
    const handleClickEvent = (event: MouseEvent) => {
      if (divRef.current && divRef.current.contains(event.target as Node)) {
        return;
      }

      onBlurTextInput();
    };

    document.addEventListener('click', handleClickEvent);

    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  React.useEffect(() => {
    if (allowCreateNew) {
      setIsExisting(autoCompleteValues.some((entity) => entity.value.name === inputValue));
    }
  }, [inputValue]);

  React.useEffect(() => {
    setInputValue(value?.value.name ?? '');
  }, [value]);

  function onFocusTextInput() {
    setIsFocused(true);
    onInputFocus();
  }

  function onBlurTextInput() {
    onInputBlur(inputValue);
    if (clearOnNew && !isExisting && inputValue.length !== 0) {
      setInputValue('');
    }
    setIsFocused(false);
  }

  return (
    <Layout.Column ref={divRef}>
      <TopDropdown
        flipDropDown={flipDropdown}
        showList={isFocused}
        autoCompleteValues={filteredAutoComplete}
        onSelection={(autoComplete) => {
          onBlurTextInput();
          onSelect(autoComplete);
        }}
        inputValue={inputValue}
        hasHeader={header.length > 0}
      />

      <LabelTextField
        label={header}
        error={error}
        styles={{
          backgroundColor: value?.value.color
            ? getColor(hueToColor(value.value.color))
            : theme.colors.inputBackground,
        }}
        active={isFocused}
      >
        <Layout.PressableRow
          onClick={() => onFocusTextInput()}
          align
          grow
          bg="transparent"
          style={{ width: '100%' }}
        >
          <InputIcon
            autoCompleteValue={value ?? { key: '', value: { iconicIcon, name: '' } }}
            active={isFocused}
          />

          <Spacer.Horizontal size={8} />

          <OpenSans.Input
            ref={inputRef}
            name="client"
            value={inputValue}
            type="text"
            placeholder={placeholder}
            grow
            style={{ outline: 'none', width: '80%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(event.target.value)
            }
            weight="light-300"
            autoComplete="off"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                inputRef.current?.blur();
                if (filteredAutoComplete[0]) {
                  onSelect(filteredAutoComplete[0]);
                }
              }
            }}
            onFocus={() => onFocusTextInput()}
            onBlur={() => onBlurTextInput()}
          />
          {!isExisting && inputValue.length !== 0 ? (
            <Layout.Row>
              <Layout.Row radius={100} px={8} py={2} bg="brandPrimary">
                <OpenSans.Inverse size="xs-12">{getTranslation('New')}</OpenSans.Inverse>
              </Layout.Row>
              <Spacer.Horizontal size={4} />
            </Layout.Row>
          ) : null}
          <Icon name="chevron-down" size={16} iconColor="primary" />
        </Layout.PressableRow>
      </LabelTextField>

      <BottomDropDown
        flipDropDown={flipDropdown}
        showList={isFocused}
        autoCompleteValues={filteredAutoComplete}
        onSelection={(autoComplete) => {
          onBlurTextInput();
          onSelect(autoComplete);
        }}
        inputValue={inputValue}
      />
    </Layout.Column>
  );
};
