import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';
const MAX_NOTE_LENGTH = 5000;

export const ReportNotes: React.FC<
  React.PropsWithChildren<{
    desc: string | undefined | null;
    onChange: (note: string) => void;
  }>
> = ({ desc, onChange }) => {
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const textbox = React.useRef<HTMLTextAreaElement>();

  function onNoteChange(value: string) {
    const notes = value.length > MAX_NOTE_LENGTH ? value.slice(0, MAX_NOTE_LENGTH) : value;
    onChange(notes);
  }

  function noteChangeHandler() {
    if (textbox.current) {
      textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    }
  }

  return (
    <LabelTextField label="Notes" active={isFocused}>
      <Layout.Column grow>
        <Spacer.Vertical size={4} />
        <Layout.Row
          style={{
            width: '100%',
          }}
        >
          <Icon name="document-text-outline" size={20} />

          <Spacer.Horizontal size={8} />

          <textarea
            ref={textbox as DoNotFixMeIAmAny}
            name="notes"
            value={desc ?? ''}
            placeholder="Notes..."
            cols={30}
            onKeyUp={() => {
              noteChangeHandler();
            }}
            style={{
              flexGrow: 1,
              width: '100%',
              border: 'none',
              outline: 'none',
              backgroundColor: theme.colors.inputBackground,
            }}
            onChange={(event) => onNoteChange(event.currentTarget.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </Layout.Row>
      </Layout.Column>
    </LabelTextField>
  );
};
