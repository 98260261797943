import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Image } from 'react-native';

export const Welcome: React.FC = () => {
  return (
    <Layout.Column grow align>
      <Spacer.Vertical size={24} />
      <OpenSans.Primary size={24} weight="bold-700" center>
        {getTranslation('Never lose another receipt')}
      </OpenSans.Primary>
      <Spacer.Vertical size={4} />
      <OpenSans.Secondary size="s-16" center>
        {getTranslation('10,000+ five star reviews from happy customers')}
      </OpenSans.Secondary>
      <Spacer.Vertical size={8} />

      <Spacer.Vertical size={24} />

      <Layout.Column grow style={{ width: '100%' }}>
        <Image
          source={require('../../../assets/ScanExample.gif')}
          style={{ width: '100%', height: '100%', resizeMode: 'contain' }}
        />
      </Layout.Column>
      <Spacer.Vertical size={32} />
    </Layout.Column>
  );
};
