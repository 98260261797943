import { useCachedReports, useWorkspaceStore } from '@easy-expense/data-firestore-client';
import { Trip } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import _ from 'lodash';
import React from 'react';

import { TripWithData } from './TripRow.component';

export const useMappedTripData = (tripKeys?: string[]): TripWithData[] => {
  const trips = Data.trips.use();
  const locations = Data.locations.use();
  const tripClasses = Data.tripClasses.use();
  const members = useWorkspaceStore((s) => s.workspaceMembers);
  const reports = useCachedReports();

  return React.useMemo(() => {
    return trips
      .filter((t) => {
        if (tripKeys) {
          return tripKeys?.includes(t.key);
        }
        return true;
      })
      .map((t) => {
        const startLocationValue = locations?.find((l) => l.key === t.startLocation)?.value;
        const endLocationValue = locations?.find((l) => l.key === t.endLocation)?.value;
        const tripClassValue = tripClasses?.find((c) => c.key === t.tripClass)?.value;
        const member = members[t.createdBy];
        const creatorDisplayName = getUserDisplayName(member);
        const reportsToAdd = Object.values(reports)?.filter((r) => r.trips?.includes(t.key));

        return {
          ...t,
          startLocationValue,
          endLocationValue,
          tripClassValue,
          startLocationValueName: startLocationValue?.name ?? '',
          endLocationValueName: endLocationValue?.name ?? '',
          tripClassValueName: tripClassValue?.name ?? '',
          creatorDisplayName,
          reports: reportsToAdd,
        };
      });
  }, [trips, locations, tripClasses, reports, members]);
};

export const applyFilters = (
  trips: TripWithData[],
  filter: (t: Trip) => boolean,
  filterUser?: string,
): TripWithData[] => {
  return trips.filter((t) => {
    if (!filter(t)) {
      return false;
    }
    if (filterUser && t.createdBy !== filterUser) {
      return false;
    }
    return true;
  });
};

export const useSearchTripList = (trips: TripWithData[], search?: string): TripWithData[] => {
  const terms = ['startLocation.name', 'endLocation.name', 'desc'];

  return React.useMemo(() => {
    if (search) {
      return trips.filter((listEntity) => {
        const entityValues = terms.map((term) => _.get(listEntity, term));

        const searchValues = entityValues.reduce((acc, entityValue) => {
          if (entityValue) {
            entityValue = typeof entityValue === 'string' ? entityValue : entityValue.toString();
            acc.push(entityValue.toLowerCase());
          }
          return acc;
        }, []);

        const expenseValuesString = searchValues.join(' ');
        return expenseValuesString.includes(search.toLowerCase());
      });
    } else {
      return trips;
    }
  }, [trips, search]);
};
