import { currentUser } from '@easy-expense/auth-client';
import { useCurrentWorkspace } from '@easy-expense/data-firestore-client';
import { WebEnv } from '@easy-expense/env-mobile';
import { Environments, initializePaddle, Paddle as PaddleInstance } from '@paddle/paddle-js';
import React from 'react';

import {
  paddleClientTokenProd,
  paddleClientTokenSandbox,
  paddleTeamItemsProd,
  paddleTeamItemsSandbox,
  TeamPlanCode,
} from '../../lib/Paddle';

export type PaddleCheckoutRef = {
  onCheckout: () => void;
};

type TeamsPlanCheckoutProps = {
  seats: number;
  planCode: TeamPlanCode;
  ref?: React.Ref<PaddleCheckoutRef>;
};

export const TeamsPlanCheckout = React.forwardRef<PaddleCheckoutRef, TeamsPlanCheckoutProps>(
  (props, ref) => {
    const { seats, planCode } = props;
    const [paddle, setPaddle] = React.useState<PaddleInstance>();
    const [paddleItemsPricings, setPaddleItemsPricings] = React.useState(paddleTeamItemsProd);
    const email = currentUser()?.email ?? '';
    const currentWorkspace = useCurrentWorkspace();
    const [successUrl, setSuccessUrl] = React.useState<string>(
      `https://${window.location.host}/teams/invite`,
    );

    React.useEffect(() => {
      let paddleEnv: Environments = 'production';
      let paddleClientToken = paddleClientTokenProd;
      if (WebEnv.buildType !== 'production') {
        console.log('using sandbox paddle');
        paddleEnv = 'sandbox';
        paddleClientToken = paddleClientTokenSandbox;
        setPaddleItemsPricings(paddleTeamItemsSandbox);
      }
      if (process.env.NODE_ENV === 'development') {
        setSuccessUrl('http://localhost:19006/teams/invite');
      }

      initializePaddle({
        environment: paddleEnv,
        token: paddleClientToken,
      })
        .then((paddleInstance: PaddleInstance | undefined) => {
          if (paddleInstance) {
            setPaddle(paddleInstance);
          }
        })
        .catch((error) => {
          console.error('ERROR with paddle: ', error);
        });
    }, []);

    function startPaddleCheckout() {
      if (!paddle) {
        alert('Something went wrong');
        return;
      }
      if (!planCode || !paddleItemsPricings[planCode]) {
        alert('No product selected');
        return;
      }
      let quantity = seats;
      if (paddleItemsPricings[planCode] === 'TEAMS_STARTER') {
        quantity = 1;
      }
      paddle.Checkout.open({
        items: [{ quantity, priceId: paddleItemsPricings[planCode] }],
        settings: { successUrl, showAddDiscounts: false },
        customer: {
          email,
        },
        customData: {
          organizationID: currentWorkspace?.org,
          workspaceID: currentWorkspace?.key,
          userID: currentUser()?.uid ?? '',
        },
      });
    }

    React.useImperativeHandle(ref, () => ({
      onCheckout: () => {
        startPaddleCheckout();
      },
    }));

    return null;
  },
);
