import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { PaddleBillingUsage } from './PaddleBillingUsage.component';
import { PaddleCustomerInformation } from './PaddleCustomerInformation.component';

export const BillingContent: React.FC = () => {
  return (
    <Layout.Column grow>
      <Spacer.Vertical size={24} />

      <Spacer.Vertical
        size={8}
        style={{
          borderTop: `1px solid ${theme.colors.inputBorder}`,
        }}
      />
      <PaddleBillingUsage />
      <Spacer.Vertical size={16} />
      <PaddleCustomerInformation />
    </Layout.Column>
  );
};
