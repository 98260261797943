import { Category, Expense } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { ScrollView } from 'react-native';

import { ScannerResultExpense, useScanReceipt } from './scanner';
import { FirestorageFileUrl, uploadReceipts } from './uploader';
import { Modal } from '../../Shared/Modal.component';

const imageRadius = 6;

export const ReceiptsField: React.FC<
  React.PropsWithChildren<{
    receipts: string[];
    onChange: (receipts: string[]) => void;
    selectedReceiptIndex: number;
    setSelectedReceiptIndex: (receiptInder: number) => void;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setExpense: React.Dispatch<React.SetStateAction<Partial<Expense>>>;
  }>
> = ({
  receipts,
  onChange,
  selectedReceiptIndex,
  setSelectedReceiptIndex,
  setLoading,
  setExpense,
}) => {
  const scanReceipt = useScanReceipt();
  const categories: Category[] = Data.expenseCategories.use();
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const selectedIndex = React.useRef(-1);
  const handleRemoveReceipt = (index: number) => {
    if (!receipts) {
      return;
    }
    const allReceipts: string[] = [...receipts];
    allReceipts.splice(index, 1);
    const newIndex = Math.max(selectedReceiptIndex - 1, 0);

    setSelectedReceiptIndex(newIndex);
    onChange(allReceipts);
  };

  function updateReceipts(imageUrls: FirestorageFileUrl[]) {
    const allReceipts: string[] = [...receipts, ...imageUrls];
    setSelectedReceiptIndex(allReceipts.length - 1);
    onChange(allReceipts);
  }

  const uncategorizedCategory = categories.find((c) => c.value.name === 'Uncategorized');

  async function onUpload(files?: FileList | null) {
    setLoading(true);

    if (files && files.length === 1 && receipts.length === 0) {
      let scannedExpense: ScannerResultExpense | undefined;
      try {
        if (files[0]) {
          scannedExpense = await scanReceipt(files[0]);
        }
      } catch (err) {
        console.warn('Failed to scan receipt ', err);
      }
      const uploadedReceipts = await uploadReceipts(files);
      setSelectedReceiptIndex(0);

      let vendorKey: string | undefined;
      if (scannedExpense?.vendor) {
        vendorKey = Data.vendors.create({ value: { name: scannedExpense.vendor } });
      }

      setExpense({
        receipts: uploadedReceipts,
        total: scannedExpense?.total,
        tax: scannedExpense?.tax,
        secondTax: scannedExpense?.secondTax,
        category: scannedExpense?.categoryKey ?? uncategorizedCategory?.key ?? '',
        vendor: vendorKey,
        paymentMethod: scannedExpense?.paymentMethodKey,
        date: scannedExpense?.date,
      });
    } else {
      const uploadedReceipts = await uploadReceipts(files);
      updateReceipts(uploadedReceipts);
    }
    setLoading(false);
  }

  return (
    <Layout.Column>
      <ConfirmationModal
        showModal={showConfirmationModal}
        handleRemoveReceipt={() => handleRemoveReceipt(selectedIndex.current)}
        setCloseModal={() => setShowConfirmationModal(false)}
      />

      <OpenSans.Secondary size="xs-12" color={'primary'} weight="bold-700">
        {getTranslation('Receipts (optional)')}
      </OpenSans.Secondary>
      <ScrollView horizontal style={{ flexDirection: 'row', paddingTop: 10, width: '100%' }}>
        <Layout.PressableColumn
          center
          border={[1, 'dashed', 'brandPrimary']}
          radius={8}
          bg="brandPrimaryXLight"
          style={{ height: 102, width: receipts.length < 1 ? 420 : 82 }}
        >
          <label htmlFor="files" style={{ cursor: 'pointer' }}>
            <Icon name="receipt-outline" size={24} color={theme.colors.brandPrimary} />
            <Spacer.Vertical size={4} />
            <OpenSans.Pressable weight="bold-700" size="s-16" center>
              {getTranslation('Add receipt')}
            </OpenSans.Pressable>
          </label>

          <input
            type="file"
            id="files"
            multiple
            accept="image/*,.pdf"
            onChange={(event) => onUpload(event.target.files)}
            style={{ display: 'none' }}
          />
        </Layout.PressableColumn>

        {receipts?.map((receipt, index) => (
          <Layout.Row key={receipt}>
            <Spacer.Horizontal size={16} />

            <Layout.PressableColumn
              border={
                index === selectedReceiptIndex ? [2, 'solid', 'primary'] : [1, 'solid', 'grayLight']
              }
              radius={imageRadius}
              style={{ width: 82, height: 100, position: 'relative' }}
              onClick={() => setSelectedReceiptIndex(index)}
              key={index}
            >
              <img
                src={receipt}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: 6,
                }}
              />
            </Layout.PressableColumn>

            <Layout.PressableColumn
              style={{
                right: -8,
                top: -8,
                position: 'absolute',
              }}
              shadow
              radius={100}
              bg="white"
              onClick={() => {
                selectedIndex.current = index;
                setShowConfirmationModal(true);
              }}
              px={2}
              py={2}
            >
              <Icon name="close-outline" size={18} />
            </Layout.PressableColumn>
          </Layout.Row>
        ))}
      </ScrollView>
    </Layout.Column>
  );
};

const ConfirmationModal: React.FC<
  React.PropsWithChildren<{
    showModal: boolean;
    handleRemoveReceipt: () => void;
    setCloseModal: () => void;
  }>
> = ({ handleRemoveReceipt, setCloseModal, showModal }) => {
  return (
    <Modal showModal={showModal} setShowModal={setCloseModal}>
      <OpenSans.Primary size="s-16" weight="bold-700" color="primary">
        {getTranslation('Are you sure you want to delete this receipt?')}
      </OpenSans.Primary>
      <Spacer.Vertical size="m-18" />

      <Layout.Row>
        <button
          style={{
            borderRadius: 22,
            border: 'none',
            paddingTop: '0.6em',
            paddingBottom: '0.6em',
            paddingLeft: '1.2em',
            paddingRight: '1.2em',
            color: theme.colors.brandPrimary,
            backgroundColor: theme.colors.brandPrimaryLight,
            fontWeight: 'bold',
            fontSize: 15,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setCloseModal();
          }}
        >
          {getTranslation('Cancel')}
        </button>
        <Spacer.Horizontal size="m-18" />
        <button
          style={{
            borderRadius: 22,
            border: 'none',
            paddingTop: '0.6em',
            paddingBottom: '0.6em',
            paddingLeft: '1.2em',
            paddingRight: '1.2em',
            color: theme.colors.destructive,
            backgroundColor: theme.colors.destructiveLight,
            fontWeight: 'bold',
            fontSize: 15,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            handleRemoveReceipt();
            setCloseModal();
          }}
        >
          {getTranslation('Confirm')}
        </button>
      </Layout.Row>
    </Modal>
  );
};
