import { LocationValue } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';

export const UpsertLocation: React.FC<{
  onDone: () => void;
  onDiscard: () => void;
  selectedLocationKey?: string;
}> = ({ onDone, onDiscard, selectedLocationKey }) => {
  const location = Data.locations.useByKey(selectedLocationKey);

  const [locationValue, setLocationValue] = React.useState<LocationValue>({
    name: location?.value?.name ?? '',
    address: location?.value?.address ?? '',
  });

  const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');
  const [addressErrorMessage, setAddressErrorMessage] = React.useState<string>('');
  const cachedLocations = Data.locations.use();
  const [nameInputFocused, setNameInputFocused] = React.useState<boolean>(false);
  const [addressInputFocused, setAddressInputFocused] = React.useState<boolean>(false);

  React.useEffect(() => {
    setNameErrorMessage('');
    setAddressErrorMessage('');

    if (locationValue.name === location?.value?.name) {
      return;
    }

    const doesSameNameExist = cachedLocations.some((loc) => loc.value.name === locationValue.name);

    if (doesSameNameExist) {
      setNameErrorMessage(getTranslation('A location with the same name already exists'));
    }
  }, [locationValue.name, locationValue.address]);

  function onSave() {
    if (nameErrorMessage || addressErrorMessage) {
      return;
    }

    try {
      if (location) {
        Data.locations.update(location.key, { value: { ...locationValue } });
      } else {
        Data.locations.create({ value: locationValue });
      }

      onDone();
    } catch (err: any) {
      if (!err?.issues) {
        alert(
          getTranslation('There was an error saving the location. Try again or contact support.'),
        );
        console.error(err);
        return;
      }

      const parsingError: any = err.issues.pop();
      if ((parsingError?.path as string[]).includes('name')) {
        setNameErrorMessage(getTranslation(`${parsingError.message}`));
      }
      if ((parsingError?.path as string[]).includes('address')) {
        setAddressErrorMessage(getTranslation(`${parsingError.message}`));
      }
    }
  }

  return (
    <Layout.Column>
      <OpenSans.Primary size="xl-28" weight="bold-700"></OpenSans.Primary>
      {getTranslation('Edit Location')}

      <Spacer.Vertical size={16} />

      <Layout.Row>
        <LabelTextField
          label="Name"
          active={nameInputFocused}
          error={!!nameErrorMessage}
          errorMessage={nameErrorMessage}
        >
          <OpenSans.Input
            autoFocus
            name="location name"
            value={locationValue?.name ?? ''}
            type="text"
            placeholder="Location Name"
            grow
            style={{ outline: 'none', width: 250 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const name = event.target.value;
              setLocationValue({ ...locationValue, name });
            }}
            autoComplete="off"
            onFocus={() => setNameInputFocused(true)}
            onBlur={() => setNameInputFocused(false)}
          />
        </LabelTextField>

        <Spacer.Horizontal size={32} />
        <LabelTextField
          label="Address"
          active={addressInputFocused}
          error={!!addressErrorMessage}
          errorMessage={addressErrorMessage}
        >
          <OpenSans.Input
            name="address"
            value={locationValue?.address ?? ''}
            type="text"
            placeholder="Address"
            grow
            style={{ outline: 'none', width: 250 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const address = event.target.value;
              setLocationValue({ ...locationValue, address });
            }}
            autoComplete="off"
            onFocus={() => setAddressInputFocused(true)}
            onBlur={() => setAddressInputFocused(false)}
          />
        </LabelTextField>
      </Layout.Row>

      <Layout.Row></Layout.Row>

      <Spacer.Vertical size={32} />

      <Layout.Row justify="flex-end">
        <Button.Secondary
          onClick={onDiscard}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 4px rgba(255, 255, 255, 1)',
          }}
        >
          <Icon
            size={15}
            color={theme.colors.brandPrimary}
            style={{ paddingRight: 10 }}
            name="chevron-back"
          />
          <OpenSans.Primary
            size={15}
            weight="bold-700"
            style={{ color: theme.colors.brandPrimary }}
          >
            {getTranslation('Discard')}
          </OpenSans.Primary>
        </Button.Secondary>
        <Spacer.Horizontal size={32} />
        <Button.Primary
          onClick={onSave}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
            width: 'auto',
          }}
        >
          <OpenSans.Custom size={15} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
            {getTranslation('Save Location')}
          </OpenSans.Custom>
          <Icon
            size={15}
            color={theme.colors.buttonWhite}
            style={{ paddingLeft: 10 }}
            name="chevron-forward"
          />
        </Button.Primary>
      </Layout.Row>
    </Layout.Column>
  );
};
