import { Report } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { lighten, darken } from 'polished';
import React from 'react';

import { AddNewReportModal } from '../../Report/ReportList.component';
import { Circle } from '../../Shape.components';
import { InsertDropdown } from '../../Shared/Dropdown/InsertableDropdown.component';

export const ReportsField: React.FC<
  React.PropsWithChildren<{
    expenseKey?: string;
    onChange: (reportKeys: string[]) => void;
  }>
> = ({ expenseKey, onChange }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [newReportName, setNewReportName] = React.useState<string>();
  const reports = Data.reports.get();
  const [expenseReports, setExpenseReports] = React.useState<Report[]>([]);
  const [otherReports, setOtherReports] = React.useState<Report[]>([]);

  function setReportSelected(newExpenseReports: Report[]) {
    setExpenseReports(newExpenseReports);
    const reportKeys = newExpenseReports.map((report) => report.key);
    onChange(reportKeys);
  }

  function onReportAdded(value: string) {
    const toAdd = reports.find((report) => report.key === value);
    const isNew = !expenseReports.find((report) => report.key === value);
    if (toAdd && isNew) {
      const newExpenseReports = [...expenseReports, toAdd];
      const newOtherReports = otherReports.filter((report) => report.key !== value);
      setReportSelected(newExpenseReports);
      setOtherReports(newOtherReports);
    }
  }

  function onReportRemoved(value: string) {
    const toRemove = expenseReports.find((report) => report.key === value);
    if (toRemove) {
      const newExpenseReports = expenseReports.filter((report) => report.key !== value);
      const newOtherReports = [...otherReports, toRemove];
      setReportSelected(newExpenseReports);
      setOtherReports(newOtherReports);
    }
  }

  function addNewReport(reportKey: string) {
    const report = Data.reports.getByKey(reportKey ?? '');
    if (report) {
      const netReports = [...expenseReports, report];
      setExpenseReports(netReports);
      onChange(netReports.map((nr) => nr.key));
    }
  }

  React.useEffect(() => {
    const expenseReports: Report[] = [];
    const otherReports: Report[] = [];

    const thereports = Data.reports.get();
    thereports.forEach((report) => {
      if (report.expenses.includes(expenseKey ?? '')) {
        expenseReports.push(report);
      } else {
        otherReports.push(report);
      }
    });

    setExpenseReports(expenseReports);
    onChange(expenseReports.map((report) => report.key));
    setOtherReports(otherReports);
  }, [expenseKey]);

  const autoCompleteValues = otherReports
    .filter((report) => report.status !== 'Complete')
    .map((report) => ({
      key: report.key,
      value: {
        name: report.name,
        color: report.color,
      },
    }));

  return (
    <Layout.Column>
      <InsertDropdown
        header={getTranslation('Reports (optional)')}
        placeholder={getTranslation('Add to Report')}
        selectedKey={undefined}
        autoCompleteValues={autoCompleteValues}
        onSelect={(c) => {
          if (c?.key) {
            onReportAdded(c?.key);
          } else if (c?.value.name) {
            setNewReportName(c.value.name);
            setShowModal(true);
          }
        }}
        clearOnNew={true}
        iconicIcon="document-outline"
        error={false}
      />

      <Layout.Column>
        {expenseReports.map((report) => (
          <Layout.Column>
            <Spacer.Vertical size={8} />
            <ReportRow key={report.key} report={report} onClick={onReportRemoved} />
          </Layout.Column>
        ))}
      </Layout.Column>

      <AddNewReportModal
        show={showModal}
        name={newReportName}
        setShowModal={(show: boolean) => {
          setShowModal(show);
        }}
        createNewReportCallback={(reportKey?: string) => {
          setShowModal(false);
          addNewReport(reportKey ?? '');
        }}
      />
    </Layout.Column>
  );
};

const ReportRow: React.FC<{
  report: Report;
  onClick: (key: string) => void;
}> = ({ report, onClick }) => {
  return (
    <Layout.Row
      style={{
        width: '100%',
        backgroundColor: lighten(0.35, `${hueToColor(report.color)}`),
        border: `1px solid ${darken(0.25, `${hueToColor(report.color)}`)}`,
      }}
      radius={6}
      px
      py={6}
      align
    >
      <Circle.Solid
        style={{ backgroundColor: darken(0.25, `${hueToColor(report.color)}`) }}
        circleSize={16}
      />
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary size="s-16">{report.name}</OpenSans.Primary>
      <Spacer.Flex />
      <Layout.PressableColumn
        onClick={() => onClick(report.key)}
        style={{ background: 'none' }}
        px={4}
        py={4}
      >
        <Icon iconColor={'grayDark'} name="close" size={20} />
      </Layout.PressableColumn>
    </Layout.Row>
  );
};
