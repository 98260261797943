import { Analytics } from '@easy-expense/analytics-client';
import { useCurrentWorkspace } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useWindowDimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';

import { ReceiptReportShareImage } from './GetStarted.component';
import { OnboardingActionColumn, OnboardingBackdrop } from './OnboardingBackdrop.component';
import { WorkspaceLoader } from './WorkspaceLoading.component';
import { auth } from '../../firebase/app';
import { TeamPlanCode } from '../../lib/Paddle';
import { createWorkspace } from '../../lib/createWorkspace';
import { SignUpForm } from '../SignUp.form';

export const SignupTeams: React.FC<React.PropsWithChildren<{ planCode: TeamPlanCode }>> = ({
  planCode,
}) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const workspace = useCurrentWorkspace();
  const [isCreatingWorkspace, setIsCreatingWorkspace] = React.useState<boolean>(false);

  const onSuccessSignUp = async (name?: string) => {
    setIsCreatingWorkspace(true);
    Analytics.track('team_onboarding_successfull_signup', { planCode });
    await createWorkspace('business', name);
  };
  React.useEffect(() => {
    if (user && workspace) {
      navigate(`/teams/pay/${planCode}`);
    }
  }, [navigate, user, workspace]);

  React.useEffect(() => {
    Analytics.track('team_onboarding_started', { planCode });
  }, []);

  const { width } = useWindowDimensions();

  if (isCreatingWorkspace) {
    return (
      <OnboardingBackdrop>
        <WorkspaceLoader />
      </OnboardingBackdrop>
    );
  }

  return (
    <OnboardingBackdrop>
      {width < 900 ? null : (
        <Layout.Column style={{ width: 430 }}>
          <Layout.Row>
            <OpenSans.Primary weight="bold-700" size={36} style={{ lineHeight: '120%' }}>
              {getTranslation('Stop chasing receipts.')}
            </OpenSans.Primary>
          </Layout.Row>
          <Spacer.Vertical />
          <Layout.Row>
            <OpenSans.Primary size={24} style={{ lineHeight: '120%' }}>
              {getTranslation('Receipt tracking made easy so employees actually use it.')}
            </OpenSans.Primary>
          </Layout.Row>

          <Spacer.Vertical />
          <ReceiptReportShareImage />
        </Layout.Column>
      )}

      <OnboardingActionColumn width={430}>
        <SignUpForm onSuccess={onSuccessSignUp} />
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
