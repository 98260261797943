import { Layout, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ExpenseTable } from './Expense/MaterialExpenseTable.component';
import { ReportList } from './Report/ReportList.component';
import { TransactionListHeader } from './TransactionListHeader.component';
import { TripTable } from './Trip/TripList';
import { TripListHeader } from './Trip/TripListHeader.component';

export const ReportContent: React.FC = () => {
  return (
    <Layout.Column px={32} py={24} style={{ height: '100%' }}>
      <ReportList showDownload />
    </Layout.Column>
  );
};

export const TransactionContent: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
      <Layout.Row style={{ minWidth: 1000 }}>
        <Spacer.Flex />
        <Layout.Column style={{ width: '100%' }}>
          <ExpenseTable
            onRowClick={(expense) => navigate(`/expense/${expense}`)}
            header={<TransactionListHeader />}
          />
        </Layout.Column>
        <Spacer.Flex />
      </Layout.Row>
    </Layout.Column>
  );
};

export const TripsContent: React.FC = () => {
  return (
    <Layout.Column py={24} px={32} grow style={{ height: '100vh', overflow: 'scroll' }}>
      <Layout.Row style={{ minWidth: 1000 }}>
        <Spacer.Flex />
        <Layout.Column style={{ width: '100%' }}>
          <TripTable onRowClick={() => {}} header={<TripListHeader />} />
        </Layout.Column>
        <Spacer.Flex />
      </Layout.Row>
    </Layout.Column>
  );
};
