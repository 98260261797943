import { useEntityFilterStore } from '@easy-expense/data-firestore-client';
import { EntityFilterSchema, Trip } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import {
  dateFormatSkeleton,
  EmptyEntityFilter,
  getTripDeduction,
} from '@easy-expense/utils-shared';
import { FixMe } from '@easy-expense/utils-typescript';
import _ from 'lodash';
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
  MaterialReactTable,
  type MRT_ColumnDef,
  MRT_TableOptions,
  MRT_FilterFn,
} from 'material-react-table';
import React from 'react';

import { TripClassBadge } from './TripClassBadge.component';
import { TripWithData } from './TripRow.component';
import { useMappedTripData } from './helpers';
import { Button } from '../Button.components';
import { Reports } from '../Expense/ExpenseRow.component';
import { ExportType } from '../Export/ExportDataType.component';
import { EntityFilters } from '../Filters/EntityFilters.component';
import { useConvertEntityFilterToColumnFilters } from '../Filters/helpers';
import { Modal } from '../Shared/Modal.component';

type ColumnFieldKeys =
  | 'trip'
  | 'date'
  | 'tripClassValueName'
  | 'creatorDisplayName'
  | 'reports'
  | 'distance'
  | 'total';

interface MaterialTripTableProps {
  onRowClick: (tripKey: string) => void;
  filter?: (t: Trip) => boolean;
  showBulk?: boolean;
  header?: React.ReactNode;
  filterUser?: string;
  selected?: string[];
  setSelected?: React.Dispatch<React.SetStateAction<string[]>>;
  isReport?: boolean;
  showSearch?: boolean;
  tripList?: string[] | undefined;
  visibleColumns?: ColumnFieldKeys[] | undefined;
  additionalOptions?: Partial<MRT_TableOptions<TripWithData>>;
}

const arrayIncludesSome: MRT_FilterFn<FixMe> = (row, columnId, filterValues) => {
  const cellValue = row.getValue(columnId) as string;
  return filterValues.includes(cellValue.toLowerCase());
};

export const TripTable: React.FC<React.PropsWithChildren<MaterialTripTableProps>> = ({
  onRowClick,
  header,
  tripList,
  visibleColumns,
  additionalOptions,
}) => {
  const { formatDate, formatCurrency } = useIntlStore();

  const storeFilter = useEntityFilterStore();
  const { setEntityFilter } = storeFilter;
  const tripClasses = Data.tripClasses.use();
  const workspaceRateDetails = Data.rateDetails.use();
  const countryRateDetails = Data.countryRateDetails.useCountryRate();

  const convert = useConvertEntityFilterToColumnFilters();

  React.useEffect(() => {
    const tableFilters = convert(storeFilter);
    table.setColumnFilters(tableFilters);
  }, [storeFilter]);

  const activeFiltersCount = React.useMemo(() => {
    const activeFilters = Object.values(storeFilter).filter((filter) => filter);
    return activeFilters.length - 1;
  }, [storeFilter]);

  const renderFiltersFooter = (
    <Layout.Row
      style={{
        borderTop: `2px solid ${theme.colors.grayXLight}`,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      }}
      bg="white"
      px={32}
      py={24}
      justify="flex-end"
      debug
    >
      <Button.Primary
        onClick={() => setShowFilters(false)}
        radius={50}
        style={{
          border: `2px solid ${theme.colors.white}`,
        }}
      >
        <OpenSans.Custom size={15} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
          {getTranslation('Apply Filters')}
        </OpenSans.Custom>
        <Icon
          size={15}
          color={theme.colors.buttonWhite}
          style={{ paddingLeft: 10 }}
          name="chevron-forward"
        />
      </Button.Primary>
    </Layout.Row>
  );

  const tripsWithData = useMappedTripData(tripList);

  const columns = React.useMemo<MRT_ColumnDef<TripWithData>[]>(() => {
    const columns: MRT_ColumnDef<TripWithData>[] = [
      {
        accessorFn: (row) => row.date,
        accessorKey: 'date',
        id: 'date',
        filterFn: 'betweenInclusive',
        header: getTranslation('Date'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Date')}</OpenSans.Secondary>
        ),
        maxSize: 10,
        Cell: ({ row }) => {
          const date = row.original.date;
          return (
            <Layout.Column style={{ width: 60 }}>
              <OpenSans.Secondary size={'s-12'}>
                {formatDate(new Date(date), {
                  skeleton: dateFormatSkeleton.abbreviatedNoYear,
                })}
              </OpenSans.Secondary>
            </Layout.Column>
          );
        },
      },
      {
        accessorKey: 'tripClassValueName',
        header: getTranslation('Classification'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Classification')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          const tripClass = row.original.tripClassValue;
          return (
            <Layout.Row style={{ maxWidth: 100 }}>
              <TripClassBadge tripClass={tripClass} />
            </Layout.Row>
          );
        },
      },
      {
        filterFn: (row, _id, filterValue) => {
          return (
            row.original.startLocationValue?.name === filterValue ||
            row.original.endLocationValue?.name === filterValue
          );
        },
        accessorKey: 'trip',
        header: getTranslation('Trip'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Trip')}</OpenSans.Secondary>
        ),
        size: 300,
        Cell: ({ row }) => {
          const startLocation = row.original.startLocationValue;
          const endLocation = row.original.endLocationValue;

          return (
            <Layout.Column style={{ maxWidth: 300 }}>
              <Layout.Row align style={{ width: '100%', overflow: 'hidden' }}>
                <OpenSans.Secondary size={14}>{startLocation?.name}</OpenSans.Secondary>
              </Layout.Row>
              <Spacer.Vertical size={2} />
              <Layout.Row align style={{ width: '100%', overflow: 'hidden' }}>
                <OpenSans.Primary size={14}>{endLocation?.name}</OpenSans.Primary>
              </Layout.Row>
            </Layout.Column>
          );
        },
      },
      {
        filterFn: arrayIncludesSome,
        accessorFn: (row) => row.creatorDisplayName,
        accessorKey: 'creatorDisplayName',
        header: getTranslation('Created By'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Created By')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-12"
                style={{
                  width: 100,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.creatorDisplayName}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        accessorKey: 'reports',
        accessorFn: (row) => row.reports.map((r) => r.name).join(''),
        header: getTranslation('Report'),
        Header: () => (
          <OpenSans.Secondary size={'s-12'}>{getTranslation('Reports')}</OpenSans.Secondary>
        ),

        size: 100,
        Cell: ({ row }) => {
          const reports = row.original.reports;
          return (
            <Layout.Row>
              <Reports reports={reports} />
            </Layout.Row>
          );
        },
      },
      {
        accessorKey: 'distance',
        header: getTranslation('Distance'),
        filterFn: 'between',
        muiTableHeadCellProps: {
          align: 'right',
        },
        Header: () => (
          <OpenSans.Secondary size={'s-12'} style={{ paddingLeft: 6 }}>
            {getTranslation('Distance')}
          </OpenSans.Secondary>
        ),
        size: 50,
        Cell: ({ row }) => {
          const value = row.original.miles;
          return (
            <Layout.Column align="flex-end">
              <OpenSans.Primary style={{ whiteSpace: 'nowrap' }} size="s-12">
                {value}mi
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },

      {
        accessorKey: 'total',
        header: getTranslation('Amount'),
        filterFn: 'between',
        muiTableHeadCellProps: {
          align: 'right',
        },
        Header: () => (
          <OpenSans.Secondary size={'s-12'} style={{ paddingLeft: 6 }}>
            {getTranslation('Amount')}
          </OpenSans.Secondary>
        ),
        size: 50,
        Cell: ({ row }) => {
          if (!row.original.tripClassValue) {
            return null;
          }
          const value = getTripDeduction(
            row.original,
            tripClasses?.find((tc) => tc.key === row.original.tripClass) ?? undefined,
            workspaceRateDetails,
            countryRateDetails,
          );
          const [dollars, cents] = formatCurrency(value).split('.');
          return (
            <Layout.Column align="flex-end">
              <OpenSans.Primary
                style={{ whiteSpace: 'nowrap' }}
                size="s-12"
                color={value > 0 ? 'successDark' : 'primary'}
                weight="bold-700"
              >
                {dollars}
                <Layout.Column justify="flex-end">
                  <OpenSans.Primary
                    size="xs-12"
                    color={value > 0 ? 'successDark' : 'primary'}
                    weight="bold-700"
                  >
                    .{cents}
                  </OpenSans.Primary>
                  <Spacer.Vertical size={1.5} />
                </Layout.Column>
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
    ];

    return columns.filter((c) => {
      return !visibleColumns || visibleColumns.includes(c.accessorKey as ColumnFieldKeys);
    });
  }, []);

  const [showFilters, setShowFilters] = React.useState(false);

  const tableParams: MRT_TableOptions<TripWithData> = {
    columns,
    data: tripsWithData,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      sorting: [{ desc: true, id: 'date' }],
      pagination: { pageIndex: 0, pageSize: 25 },
    },

    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
      FilterAltIcon: () => <Icon name="funnel" size={18} color={theme.colors.secondary} />,
      ViewColumnIcon: () => <Icon name="build-outline" size={18} color={theme.colors.secondary} />,
    },
    enableColumnActions: false,
    columnFilterDisplayMode: 'custom',
    getRowId: (row) => row.key,
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.id),
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py align>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                width: 500,
                paddingRight: 1,
              },
            }}
          />

          <Spacer.Horizontal size={16} />

          <Layout.PressableRow
            align
            bg="inputBackground"
            border={[1, 'solid', 'inputBorder']}
            px
            py={6}
            radius={100}
            onClick={() => {
              setShowFilters(true);
            }}
          >
            <Icon name="funnel-outline" size={16} color="brandPrimary" />
            <Spacer.Horizontal size={8} />
            <OpenSans.Primary>Add Filter</OpenSans.Primary>
          </Layout.PressableRow>

          {table.getState().columnFilters.length > 0 ? (
            <Layout.Row align>
              <Spacer.Horizontal />

              <Layout.Column style={{ width: '1px', height: 32 }} bg="grayLight" />

              <Spacer.Horizontal />

              <Layout.PressableRow
                align
                bg="brandPrimaryXLight"
                border={[1, 'solid', 'brandPrimary']}
                px
                py={6}
                radius={100}
                onClick={() => {
                  table.setColumnFilters([]);
                  setEntityFilter(EmptyEntityFilter);
                }}
              >
                <Icon name="close-outline" size={16} color={theme.colors.brandPrimary} />
                <Spacer.Horizontal size={8} />
                <OpenSans.Pressable>
                  {getTranslation('Clear') +
                    ' ' +
                    activeFiltersCount +
                    ' ' +
                    getTranslation('Filters')}
                </OpenSans.Pressable>
              </Layout.PressableRow>
            </Layout.Row>
          ) : null}
        </Layout.Row>
      );
    },
    ...additionalOptions,
  };

  const completeTableParams = _.merge(tableParams, additionalOptions);

  const table = useMaterialReactTable(completeTableParams);

  return (
    <Layout.Column>
      <Layout.Row>{header}</Layout.Row>
      <MaterialReactTable table={table} />

      <Modal showModal={showFilters} setShowModal={setShowFilters} footer={renderFiltersFooter}>
        <Layout.Column grow style={{ overflow: 'scroll' }}>
          <EntityFilters
            filter={storeFilter}
            dataType={ExportType.trips}
            setFilter={(filter) => {
              setEntityFilter(EntityFilterSchema.parse(filter));
            }}
            range={'custom'}
            hideIncomes={true}
          />
        </Layout.Column>
      </Modal>
    </Layout.Column>
  );
};
