import { DataExportRequestCRUD } from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  DataExportRequest,
  DataExportRequestSchema,
  EntityFilter,
  EntityFilterSchema,
  ExportFileTypeValue,
  PDFTemplateSchema,
} from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { theme } from '@easy-expense/ui-theme';
import { Layout, Spacer, zIndex } from '@easy-expense/ui-web-core';
import { EmptyEntityFilter } from '@easy-expense/utils-shared';
import cuid from 'cuid';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';

import { ExportDataType, ExportType } from './ExportDataType.component';
import { Range } from './ExportDateFilter.component';
import { ExportFileType } from './ExportFileType.component';
import { ExportHeader } from './ExportHeader.component';
import { ExportSettingComponent } from './ExportSettings.component';
import { auth } from '../../firebase/app';
import { useSubmitDataExportRequest } from '../../hooks/downloads/useDownloadReport';
import { Button } from '../Button.components';
import { EntityFilters } from '../Filters/EntityFilters.component';
import LoadingSpinner from '../LoadingSpinner.component';

export const ExportContent: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [key, setKey] = React.useState<string>(cuid());
  const [user] = useAuthState(auth);

  const location = useLocation() as unknown as { state?: { storeFilter?: string } };

  const [dateRange, setDateRange] = React.useState<Range>();

  const [usedRequest] = DataExportRequestCRUD.useRequest(key);
  const { onSubmit } = useSubmitDataExportRequest(key, user?.uid ?? 'none');

  const [dataExport, setDataExport] = React.useState<DataExportRequest>(
    DataExportRequestSchema.parse({
      key,
      ...entityFields('create', user?.uid ?? ''),
      fileType: 'csv',
      downloadAttachments: false,
      splitUser: false,
      error: null,
      exportedFileURL: null,
      sharedResourceID: null,
      destination: null,
    }),
  );

  const [dataType, setDataType] = React.useState<ExportType>(ExportType.transactions);

  // DO NOT USE setFilterHidden USE setFilter otherwise state can be overwritten in a race condition
  const [filter, setFilterHidden] = React.useState<EntityFilter>({
    ...EmptyEntityFilter,
  });

  // Make sure if you set a filter on mount you call setFilter with the partial of what is updated not the entire filter object to avoid race condition overwrites
  const setFilter = (filter: Partial<EntityFilter>) => {
    setFilterHidden((prev) => {
      return {
        ...prev,
        ...filter,
      };
    });
  };

  React.useEffect(() => {
    if (location.state?.storeFilter) {
      const filter = EntityFilterSchema.parse(JSON.parse(location.state.storeFilter));
      if (filter.startDate || filter.endDate) {
        //IF we wanna add the specifc range based on time period (reverse calculate range) this is where you would do that
        setDateRange('custom');
      } else {
        setDateRange('all time');
      }
      setFilter(filter);
    }
  }, [location.state?.storeFilter]);

  React.useEffect(() => {
    setDataExport({ ...dataExport, filter });
  }, [filter]);

  React.useEffect(() => {
    if (dataType === ExportType.trips) {
      setFilter({
        removeTrips: null,
        removeExpenses: true,
        removeIncomes: true,
      });
    } else {
      setFilter({
        removeTrips: true,
        removeExpenses: null,
        removeIncomes: null,
      });
    }
  }, [dataType]);

  function downloadExport() {
    setLoading(true);
    onSubmit(dataExport);
  }

  React.useEffect(() => {
    directDownloadLink(usedRequest?.exportedFileURL);
  }, [usedRequest]);

  React.useEffect(() => {
    //TODO: Alert error: https://linear.app/easy-expense/issue/EE-3452/alert-errors-on-export-failure
    if (usedRequest?.error) {
      setLoading(false);
    }
  }, [usedRequest?.error]);

  function directDownloadLink(url?: string | null) {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url || 'downloaded-file';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setLoading(false);
      setKey(cuid());
    }
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100%' }}>
      <Layout.Column
        py={24}
        px={32}
        grow
        style={{ width: '100%', overflow: 'scroll', minWidth: 1000 }}
      >
        {loading ? (
          <Layout.Column
            style={{
              width: '100vh',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              justifyContent: 'center',
              position: 'absolute',
              zIndex: zIndex.LoadingOverlay,
            }}
          >
            <LoadingSpinner />
          </Layout.Column>
        ) : null}

        <Layout.Column style={{ width: '100%' }} grow>
          <ExportHeader onDownload={() => downloadExport()} />

          <ExportFileType
            fileType={dataExport.fileType}
            setFileType={(fileType: ExportFileTypeValue) => {
              let pdfOptions = null;
              if (fileType === 'pdf') {
                pdfOptions = {
                  title: 'PDF',
                  template: PDFTemplateSchema.Values.report,
                };
              }
              setDataExport({
                ...dataExport,
                fileType,
                pdfOptions,
              });
            }}
          />
          <ExportDataType
            dataType={dataType}
            setDataType={(dataType: ExportType) => {
              setDataType(dataType);
            }}
          />
          <Spacer.Vertical size={12} />

          <EntityFilters
            range={dateRange}
            dataType={dataType}
            setFilter={setFilter}
            filter={dataExport.filter}
          />

          <Spacer.Vertical size={12} />

          <ExportSettingComponent
            setDataExport={(dataExport: DataExportRequest) => {
              setDataExport({
                ...dataExport,
              });
            }}
            dataExport={dataExport}
          />

          <Spacer.Vertical size={16} />
        </Layout.Column>
      </Layout.Column>
      <Layout.Column
        style={{
          borderTop: `2px solid ${theme.colors.grayXLight}`,
        }}
        bg="white"
        px={32}
        py={12}
      >
        <Spacer.Vertical size={12} />
        <Layout.Row>
          <Button.Primary
            px={24}
            py
            radius={50}
            center
            content={getTranslation('Download')}
            onClick={() => downloadExport()}
          />
        </Layout.Row>
      </Layout.Column>
    </Layout.Column>
  );
};
