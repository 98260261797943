import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import React from 'react';

import { BaseAutoCompleteInputParams } from './AutoCompleteTypes';
import { BottomDropDown } from './BottomDropDown';
import { InputIcon } from './InputIcon';
import { TopDropdown } from './TopDropdown';
import { getColor } from './helper';
import { LabelTextField } from '../../LabelTextField.component';

export const StaticDropDown: React.FC<React.PropsWithChildren<BaseAutoCompleteInputParams>> = ({
  header,
  value,
  autoCompleteValues,
  onSelect,
  allowCreateNew,
  error,
  iconicIcon,
  onInputBlur,
  onInputFocus,
}) => {
  const [inputValue, setInputValue] = React.useState(value?.value.name ?? '');

  const [isExisting, setIsExisting] = React.useState(true);
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [flipDropdown, setFlipDropdown] = React.useState(false);

  const filteredAutoComplete = autoCompleteValues.filter((v) => {
    return v.value.name.toLowerCase().includes(inputValue.toLowerCase());
  });

  if (!isExisting && allowCreateNew && inputValue.length !== 0) {
    filteredAutoComplete.push({
      value: { iconicIcon: 'add-circle-outline', name: inputValue },
    });
  }

  React.useEffect(() => {
    const checkPosition = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        setFlipDropdown(rect.top > windowHeight / 2 && windowHeight - rect.bottom < 300);
      }
    };
    checkPosition();
    window.addEventListener('scroll', checkPosition, true);
    return () => {
      window.removeEventListener('scroll', checkPosition, true);
    };
  }, []);

  React.useEffect(() => {
    const handleClickEvent = (event: MouseEvent) => {
      if (divRef.current && divRef.current.contains(event.target as Node)) {
        return;
      }

      onBlurTextInput();
    };

    document.addEventListener('click', handleClickEvent);

    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  React.useEffect(() => {
    if (allowCreateNew) {
      setIsExisting(autoCompleteValues.some((entity) => entity.value.name === inputValue));
    }
  }, [inputValue]);

  React.useEffect(() => {
    setInputValue(value?.value.name ?? '');
  }, [value]);

  function onFocusTextInput() {
    setIsFocused(true);
    onInputFocus();
  }

  function onBlurTextInput() {
    onInputBlur(inputValue);
    setIsFocused(false);
  }

  return (
    <Layout.Column ref={divRef}>
      <TopDropdown
        flipDropDown={flipDropdown}
        showList={isFocused}
        autoCompleteValues={filteredAutoComplete}
        onSelection={(autoComplete) => {
          onBlurTextInput();
          onSelect(autoComplete);
        }}
        inputValue={inputValue}
        hasHeader={header.length > 0}
      />

      <LabelTextField
        label={header}
        error={error}
        styles={{
          width: 200,
          backgroundColor: value?.value.color
            ? getColor(hueToColor(value.value.color))
            : theme.colors.inputBackground,
        }}
        active={isFocused}
      >
        <Layout.PressableRow onClick={() => onFocusTextInput()} align grow bg="transparent" debug>
          <InputIcon
            autoCompleteValue={value ?? { key: '', value: { iconicIcon, name: '' } }}
            active={isFocused}
          />

          <Spacer.Horizontal size={8} />

          <OpenSans.Primary grow>{value?.value.name ?? ''}</OpenSans.Primary>

          <Icon name="chevron-down" size={16} iconColor="primary" />
        </Layout.PressableRow>
      </LabelTextField>

      <BottomDropDown
        flipDropDown={flipDropdown}
        showList={isFocused}
        autoCompleteValues={filteredAutoComplete}
        onSelection={(autoComplete) => {
          onBlurTextInput();
          onSelect(autoComplete);
        }}
        inputValue={inputValue}
      />
    </Layout.Column>
  );
};
