import { Client } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import {
  MRT_GlobalFilterTextField,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import React from 'react';

import { UpsertClient } from './UpsertClient.component';
import { Modal } from '../Shared/Modal.component';

export const ClientsTable: React.FC<React.PropsWithChildren<object>> = () => {
  const clients = Data.clients.use();
  const [showAddClientModal, setShowAddClientModal] = React.useState(false);
  const [selectedClientKey, setSelectedClientKey] = React.useState<string | undefined>(undefined);

  const columns = React.useMemo<MRT_ColumnDef<Client>[]>(
    () => [
      {
        id: 'key',
        header: '',
        maxSize: 10,
        Cell: ({ row }) => {
          return (
            <Layout.PressableRow
              border={[1, 'solid', 'destructiveDark']}
              align
              center
              style={{ backgroundColor: theme.colors.destructiveXLight, width: 32, height: 32 }}
              radius={100}
              onClick={(e: any) => {
                e.stopPropagation();
                if (!row.original.key) {
                  return;
                }
                Data.clients.archive(row.original.key);
              }}
            >
              <Icon name="trash-outline" size={16} color={theme.colors.destructiveDark} />
            </Layout.PressableRow>
          );
        },
      },
      {
        accessorKey: 'value.name',
        header: getTranslation('Client'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Client')}</OpenSans.Secondary>
        ),
        size: 200,
        Cell: ({ row }) => {
          return <OpenSans.Primary weight="bold-700">{row.original.value.name}</OpenSans.Primary>;
        },
      },
      {
        accessorKey: 'value.email',
        header: getTranslation('Email'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Email')}</OpenSans.Secondary>
        ),
        size: 200,
        Cell: ({ row }) => {
          return <OpenSans.Primary weight="bold-700">{row.original.value.email}</OpenSans.Primary>;
        },
      },
      {
        accessorKey: 'value.phone',
        header: getTranslation('Phone'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Phone')}</OpenSans.Secondary>
        ),
        size: 200,
        Cell: ({ row }) => {
          return <OpenSans.Primary weight="bold-700">{row.original.value.phone}</OpenSans.Primary>;
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: clients,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 25 },
      sorting: [{ desc: false, id: 'value.name' }],
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
    },
    autoResetPageIndex: false,
    autoResetExpanded: false,
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.key ?? '',
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: () => displayClientEditModal(row),
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />

          <Spacer.Horizontal size={16} />

          <Layout.PressableRow
            onClick={() => {
              setSelectedClientKey('');
              setShowAddClientModal(true);
            }}
            bg="brandPrimaryXLight"
            border={[1, 'solid', 'brandPrimary']}
            py={4}
            px
            radius={100}
            align
          >
            <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
            <Spacer.Horizontal size={8} />
            <OpenSans.Pressable>{getTranslation('Add Client')}</OpenSans.Pressable>
          </Layout.PressableRow>
        </Layout.Row>
      );
    },
  });

  function displayClientEditModal(row: MRT_Row<Client>) {
    setSelectedClientKey(row.original.key);
    setShowAddClientModal(true);
  }

  return (
    <Layout.Column>
      <Spacer.Vertical size={24} />

      <Spacer.Vertical
        size={12}
        style={{
          borderTop: `1px solid ${theme.colors.inputBorder}`,
        }}
      />

      <MaterialReactTable table={table} />

      <Modal showModal={showAddClientModal} setShowModal={setShowAddClientModal}>
        <UpsertClient
          onDone={() => setShowAddClientModal(false)}
          onDiscard={() => setShowAddClientModal(false)}
          selectedClientKey={selectedClientKey}
        />
      </Modal>
    </Layout.Column>
  );
};
