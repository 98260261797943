import { useIntlStore } from '@easy-expense/intl-client';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

export const CurrencyField: React.FC<
  React.PropsWithChildren<{
    initialValue?: number;
    updatedValue?: number;
    error?: boolean;
    onChange: (value: number) => void;
    label: string;
    allowNegative?: boolean;
  }>
> = ({ initialValue, error, onChange, label, allowNegative, updatedValue }) => {
  const [value, setValue] = React.useState<string>(initialValue?.toFixed(2) ?? '');

  React.useEffect(() => {
    if (updatedValue !== undefined && !isFocused) {
      setValue(updatedValue.toFixed(2));
    }
  }, [updatedValue]);

  const [isFocused, setIsFocused] = React.useState(false);
  const currency = useIntlStore().cldr.Numbers.getCurrencySymbol(useIntlStore().currency);

  function numberWithCommas(x: number | string) {
    const t = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return t === 'NaN' ? '0' : t;
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const input = event.target;
    const negative = input.value.includes('-') && allowNegative;
    const cleanedInput = input.value
      .replace(/[^0-9.]/g, '') // Remove non-digits except for the decimal point
      .replace(/\.(?=.*\.)/g, '') // Remove all but the first decimal point
      .replace(/(\.\d{2})\d+/g, '$1') // Keep only two digits after the first decimal point
      .substring(0, 11);

    onChange((negative ? -1 : 1) * parseFloat(cleanedInput));
    setValue((negative ? '-' : '') + numberWithCommas(cleanedInput));
  }

  return (
    <LabelTextField label={label} active={isFocused} error={error}>
      <Layout.Row style={{ width: '100%' }} align>
        <OpenSans.Primary style={{ marginRight: 6 }} weight="light-300">
          {currency}
        </OpenSans.Primary>
        <Layout.Row grow>
          <OpenSans.Input
            value={value}
            type="text"
            weight="light-300"
            placeholder="0.00"
            style={{ flexGrow: 1, outline: 'none', width: '100%' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onInputChange(event)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </Layout.Row>
      </Layout.Row>
    </LabelTextField>
  );
};
