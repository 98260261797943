import { ClientValue } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { validateEmail } from '@easy-expense/utils-shared';
import React from 'react';

import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';

export const UpsertClient: React.FC<{
  onDone: () => void;
  onDiscard: () => void;
  selectedClientKey?: string;
}> = ({ onDone, onDiscard, selectedClientKey }) => {
  const client = Data.clients.useByKey(selectedClientKey);

  const [clientValue, setClientValue] = React.useState<ClientValue>({
    name: client?.value?.name ?? '',
    email: client?.value?.email,
    phone: client?.value?.phone,
  });

  const [nameErrorMessage, setNameErrorMessage] = React.useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>('');
  const [phoneErrorMessage, setPhoneErrorMessage] = React.useState<string>('');
  const cachedClients = Data.clients.use();
  const [nameInputFocused, setNameInputFocused] = React.useState<boolean>(false);
  const [emailInputFocused, setEmailInputFocused] = React.useState<boolean>(false);
  const [phoneInputFocused, setPhoneInputFocused] = React.useState<boolean>(false);

  React.useEffect(() => {
    setNameErrorMessage('');
    setEmailErrorMessage('');
    setPhoneErrorMessage('');

    if (clientValue.email && !validateEmail(clientValue.email)) {
      setEmailErrorMessage('Invalid email format');
    }

    if (clientValue.name === client?.value?.name) {
      return;
    }

    const doesSameNameClientExist = cachedClients.some(
      (client) => client.value.name === clientValue.name,
    );

    if (doesSameNameClientExist) {
      setNameErrorMessage('A client with the same name already exists');
    }
  }, [clientValue.name, clientValue.email, clientValue.phone]);

  function onSave() {
    if (nameErrorMessage || emailErrorMessage || phoneErrorMessage) {
      return;
    }

    try {
      if (client) {
        Data.clients.update(client.key, { value: { ...clientValue } });
      } else {
        Data.clients.create({ value: clientValue });
      }

      onDone();
    } catch (err: any) {
      if (!err?.issues) {
        alert(
          getTranslation('There was an error saving the client. Try again or contact support.'),
        );
        console.error(err);
        return;
      }

      const parsingError: any = err.issues.pop();
      if ((parsingError?.path as string[]).includes('name')) {
        setNameErrorMessage(getTranslation(`${parsingError.message}`));
      }
      if ((parsingError?.path as string[]).includes('email')) {
        setEmailErrorMessage(getTranslation(`${parsingError.message}`));
      }
      if ((parsingError?.path as string[]).includes('phone')) {
        setPhoneErrorMessage(getTranslation(`${parsingError.message}`));
      }
    }
  }

  return (
    <Layout.Column>
      <OpenSans.Primary size="xl-28" weight="bold-700">
        {getTranslation('Edit Client')}
      </OpenSans.Primary>

      <Spacer.Vertical size={16} />

      <Layout.Row>
        <LabelTextField
          label="Name"
          active={nameInputFocused}
          error={!!nameErrorMessage}
          errorMessage={nameErrorMessage}
        >
          <OpenSans.Input
            autoFocus
            name="client name"
            value={clientValue?.name ?? ''}
            type="text"
            placeholder="Client Name"
            grow
            style={{ outline: 'none', width: 250 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const name = event.target.value;
              setClientValue({ ...clientValue, name });
            }}
            autoComplete="off"
            onFocus={() => setNameInputFocused(true)}
            onBlur={() => setNameInputFocused(false)}
          />
        </LabelTextField>

        <Spacer.Horizontal size={32} />
        <LabelTextField
          label="Email"
          active={emailInputFocused}
          error={!!emailErrorMessage}
          errorMessage={emailErrorMessage}
        >
          <OpenSans.Input
            autoFocus
            name="client email"
            value={clientValue?.email ?? ''}
            type="text"
            placeholder="Client Email"
            grow
            style={{ outline: 'none', width: 250 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const email = event.target.value;
              setClientValue({ ...clientValue, email });
            }}
            autoComplete="off"
            onFocus={() => setEmailInputFocused(true)}
            onBlur={() => setEmailInputFocused(false)}
          />
        </LabelTextField>

        <Spacer.Horizontal size={32} />
        <LabelTextField
          label="Phone"
          active={phoneInputFocused}
          error={!!phoneErrorMessage}
          errorMessage={phoneErrorMessage}
        >
          <OpenSans.Input
            autoFocus
            name="client phone"
            value={clientValue?.phone ?? ''}
            type="text"
            pattern="\d*"
            grow
            style={{ outline: 'none', width: 250 }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const phone = event.target.value.replace(/\D/g, '').slice(0, 11);
              setClientValue({ ...clientValue, phone });
            }}
            autoComplete="off"
            onFocus={() => setPhoneInputFocused(true)}
            onBlur={() => setPhoneInputFocused(false)}
          />
        </LabelTextField>
      </Layout.Row>

      <Layout.Row></Layout.Row>

      <Spacer.Vertical size={32} />

      <Layout.Row justify="flex-end">
        <Button.Secondary
          onClick={onDiscard}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 4px rgba(255, 255, 255, 1)',
          }}
        >
          <Icon
            size={15}
            color={theme.colors.brandPrimary}
            style={{ paddingRight: 10 }}
            name="chevron-back"
          />
          <OpenSans.Primary
            size={15}
            weight="bold-700"
            style={{ color: theme.colors.brandPrimary }}
          >
            {getTranslation('Discard')}
          </OpenSans.Primary>
        </Button.Secondary>
        <Spacer.Horizontal size={32} />
        <Button.Primary
          onClick={onSave}
          radius={50}
          style={{
            border: '2px solid #fff',
            boxShadow: '0 0 8px rgba(255, 255, 255, .5)',
            width: 'auto',
          }}
        >
          <OpenSans.Custom size={15} weight="bold-700" style={{ color: theme.colors.buttonWhite }}>
            {getTranslation('Save Client')}
          </OpenSans.Custom>
          <Icon
            size={15}
            color={theme.colors.buttonWhite}
            style={{ paddingLeft: 10 }}
            name="chevron-forward"
          />
        </Button.Primary>
      </Layout.Row>
    </Layout.Column>
  );
};
