import { Analytics } from '@easy-expense/analytics-client';
import { getDetailedReceiptEnrichmentFn } from '@easy-expense/auth-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'react-native';

import { ScannedReceiptResults } from './ScannedReceiptResults';
import { scanTextFromFile } from '../../components/Expense/Edit/scanner';
import { uploadReceipts } from '../../components/Expense/Edit/uploader';
import LoadingSpinner from '../../components/LoadingSpinner.component';
import { useIsMobile } from '../../hooks/useWindow';

export type OnboardingExpense = {
  vendor: string;
  total: number;
  tax: [{ type: string; amount: number }];
  category: { emoji: string; name: string };
  date?: string;
  line_number?: string;
  image_url?: string;
};

const demoReceipts: OnboardingExpense[] = [
  {
    vendor: 'Home Depot',
    total: 198.73,
    tax: [{ type: 'Tax', amount: 8.87 }],
    category: { emoji: '🖇️', name: 'Supplies and Materials' },
    date: '2024-12-17',
    line_number: '11',
    image_url: require('./HomeDepot_small.webp'),
  },
  {
    vendor: '5 Guys',
    total: 13.27,
    tax: [{ type: 'GST', amount: 0.55 }],
    category: { emoji: '🍔', name: 'Business Meals' },
    date: '2024-07-03',
    line_number: '19',
    image_url: require('./5guysreceipt.webp'),
  },
  {
    vendor: 'Backcountry Brewing',
    total: 78.35,
    tax: [{ type: 'GST', amount: 3.35 }],
    category: { emoji: '🍔', name: 'Business Meals' },
    date: '2024-07-20',
    line_number: '19',
    image_url: require('./backcountryreceipt.webp'),
  },
];

const convertLLMResponse = (llmResponse: string): OnboardingExpense => {
  let responseAsObject: DoNotFixMeIAmAny = {};

  try {
    responseAsObject = JSON.parse(llmResponse);
  } catch (error) {
    console.error('Error Parsing JSON:', error);
    // Handle the error case by returning default values
    return {
      vendor: 'Unknown Vendor',
      total: 0,
      tax: [{ type: 'Tax', amount: 0 }],
      category: {
        emoji: '',
        name: 'Uncategorized',
      },
      date: new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date()),
    };
  }

  return {
    vendor: responseAsObject['vendor_name'] ?? 'Unknown Vendor',
    total: responseAsObject['total'] ?? 0,
    tax: responseAsObject['tax'] ?? [],
    category: {
      emoji: responseAsObject['category_emoji'] ?? '',
      name: responseAsObject['category'] ?? 'Uncategorized',
    },
    date: new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(responseAsObject['date'])),
    line_number: responseAsObject['schedule_c_line_number'] ?? '16',
  };
};

export const ReceiptScanner = ({
  setScannedReceipt,
  scannedReceipt,
  countryCode,
}: {
  setScannedReceipt: (scanned: OnboardingExpense) => void;
  scannedReceipt?: OnboardingExpense;
  countryCode?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState<OnboardingExpense | undefined>(scannedReceipt);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();

  const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      onUpload(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
  };

  const onUpload = async (files: FileList | null) => {
    setIsLoading(true);

    if (files && files.length === 1 && files[0] !== undefined) {
      const file = files[0];
      if (file) {
        try {
          const receiptText = await scanTextFromFile(file);
          const response = await getDetailedReceiptEnrichmentFn()({ receiptText });
          const convertedReceipt = convertLLMResponse(response.data.llmResponse);

          const uploadedReceipt = await uploadReceipts(files);
          if (uploadedReceipt) {
            Analytics.track('receipt_scanned', { type: 'uploaded' });
            setReceipt({ ...convertedReceipt, image_url: uploadedReceipt[0] });
          }
          setIsLoading(false);
        } catch (_) {
          setIsLoading(false);
        }
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (receipt) {
      setScannedReceipt(receipt);
    }
  }, [receipt]);

  if (isLoading) {
    return (
      <Layout.Column grow center>
        <LoadingSpinner />
        <Spacer.Vertical size={16} />
        <OpenSans.Primary>{getTranslation('Scanning for Deductions...')}</OpenSans.Primary>
      </Layout.Column>
    );
  }

  if (receipt) {
    return <ScannedReceiptResults countryCode={countryCode ?? 'US'} result={receipt} />;
  }

  return (
    <Layout.Column align>
      <Layout.Column align style={{ maxWidth: 600, width: '100%' }}>
        <Spacer.Vertical size={16} />
        <Layout.Row grow>
          <OpenSans.Primary size={24} weight="bold-700" center>
            {getTranslation('Add a receipt and find your first tax deduction.')}
          </OpenSans.Primary>
        </Layout.Row>
        <Spacer.Vertical size={40} />

        <Layout.PressableColumn bg="transparent">
          <label
            htmlFor="files"
            style={{
              borderStyle: 'dashed',
              borderWidth: 1,
              borderRadius: 6,
              borderColor: theme.colors.brandPrimary,
              backgroundColor: theme.colors.white,
              padding: 32,
              display: 'flex',
              cursor: 'pointer',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <Layout.Column center style={{ justifyContent: 'space-between' }}>
              <Icon name="receipt-outline" size={23} color={theme.colors.brandPrimary} />
              <Spacer.Vertical size={12} />
              <OpenSans.Pressable weight="bold-700">
                {isMobile
                  ? getTranslation('Tap here to upload a receipt')
                  : getTranslation('Drag & drop receipts')}
              </OpenSans.Pressable>
              <Spacer.Vertical size={4} />
              <OpenSans.Pressable size="xs-12">
                {getTranslation('We will scan the details automatically')}
              </OpenSans.Pressable>
              <input
                ref={fileInputRef}
                type="file"
                id="files"
                multiple
                accept="image/*,.pdf"
                onChange={(event) => onUpload(event.target.files)}
                style={{ display: 'none' }}
              />
            </Layout.Column>
          </label>
        </Layout.PressableColumn>

        <Spacer.Vertical size={40} />

        <Layout.Row style={{ width: '100%' }} align>
          <Spacer.Horizontal size={20} />
          <Layout.Column grow style={{ height: 1 }} bg="secondary" />
          <Spacer.Horizontal size={20} />
          <OpenSans.Secondary>OR</OpenSans.Secondary>
          <Spacer.Horizontal size={20} />
          <Layout.Column grow style={{ height: 1 }} bg="secondary" />
          <Spacer.Horizontal size={20} />
        </Layout.Row>

        <Spacer.Vertical size={40} />

        <OpenSans.Primary>{getTranslation('Select a demo receipt:')}</OpenSans.Primary>
        <Spacer.Vertical size={20} />

        <Layout.Row>
          <Layout.PressableColumn
            bg="white"
            radius
            border={[2, 'solid', 'brandPrimary']}
            center
            style={{ width: 70, height: 100 }}
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                Analytics.track('receipt_scanned', { type: 'demo' });
                setReceipt(demoReceipts[0]);
              }, 2000);
            }}
          >
            <Image
              source={require('./HomeDepot_small.webp')}
              style={{ width: '100%', height: '100%', borderRadius: 8 }}
            />
          </Layout.PressableColumn>
          <Spacer.Horizontal size={24} />
          <Layout.PressableColumn
            bg="white"
            radius
            border={[2, 'solid', 'brandPrimary']}
            center
            style={{ width: 70, height: 100 }}
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                Analytics.track('receipt_scanned', { type: 'demo' });
                setReceipt(demoReceipts[1]);
              }, 2000);
            }}
          >
            <Image
              source={require('./5guysreceipt.webp')}
              style={{ width: '100%', height: '100%', borderRadius: 8 }}
            />
          </Layout.PressableColumn>
          <Spacer.Horizontal size={24} />
          <Layout.PressableColumn
            bg="white"
            radius
            border={[2, 'solid', 'brandPrimary']}
            center
            style={{ width: 70, height: 100 }}
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
                Analytics.track('receipt_scanned', { type: 'demo' });
                setReceipt(demoReceipts[2]);
              }, 2000);
            }}
          >
            <Image
              source={require('./backcountryreceipt.webp')}
              style={{ width: '100%', height: '100%', borderRadius: 8 }}
            />
          </Layout.PressableColumn>
        </Layout.Row>

        <Spacer.Vertical size={40} />
      </Layout.Column>
    </Layout.Column>
  );
};
