import {
  useCachedWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme/src/Theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { formatNameAsInitials } from '@easy-expense/utils-shared';
import React from 'react';
import { Image } from 'react-native';

import { uploadImage } from '../../Expense/Edit/uploader';
import LoadingSpinner from '../../LoadingSpinner.component';

export const WorkspaceIcon: React.FC = () => {
  const workspace = useCachedWorkspace();
  const abbr = formatNameAsInitials(workspace.name);
  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');
  const [iconLoading, setIconLoading] = React.useState<boolean>(false);

  function onRemove() {
    updateWorkspace({ logoURL: null });
  }

  async function onUpload(files?: FileList | null) {
    if (files && files[0]) {
      setIconLoading(true);
      const url = await uploadImage(files[0]);
      setIconLoading(false);
      if (url) {
        updateWorkspace({ logoURL: url });
      }
    }
  }

  const WorkspaceIconLabel = () => {
    if (iconLoading) {
      return <LoadingSpinner />;
    }
    if (workspace.logoURL) {
      return <Image source={{ uri: workspace.logoURL }} style={{ height: 100, width: 100 }} />;
    }
    return (
      <OpenSans.Primary weight="bold-700" size="3xl-38">
        {abbr}
      </OpenSans.Primary>
    );
  };

  return (
    <Layout.Row
      align
      style={{
        borderTop: `1px solid ${theme.colors.inputBorder}`,
      }}
      px
      py
    >
      <Layout.Column style={{ maxWidth: 350 }}>
        <Layout.Row align>
          <OpenSans.Primary size={16} weight="bold-700">
            {getTranslation('Workspace Icon')}
          </OpenSans.Primary>
        </Layout.Row>
        <Layout.Row align>
          <OpenSans.Secondary size={16}>
            {getTranslation('Select and icon that represents your workspace, company or brand.')}
          </OpenSans.Secondary>
        </Layout.Row>
      </Layout.Column>
      <Spacer.Horizontal size={24} />
      <Layout.Column>
        <Layout.Row align>
          <Layout.Column
            px={workspace.logoURL ? 0 : 8}
            py={workspace.logoURL ? 0 : 8}
            bg="brandPrimaryLight"
            radius={6}
            center
            style={{ minHeight: 100, minWidth: 100 }}
          >
            <WorkspaceIconLabel />
          </Layout.Column>

          <Spacer.Horizontal size={16} />
          <Layout.Column>
            <Layout.PressableRow
              onClick={() => {}}
              border={[1, 'solid', 'inputBorder']}
              bg={'inputBackground'}
              py={4}
              px
              radius={5}
            >
              <label htmlFor="files" style={{ cursor: 'pointer' }}>
                <Layout.Row align>
                  <Icon name="image-outline" size={16} color={theme.colors.primary} />
                  <Spacer.Horizontal size={8} />
                  <OpenSans.Primary color={'primary'}>
                    {getTranslation('Replace Icon')}
                  </OpenSans.Primary>
                </Layout.Row>
              </label>

              <input
                type="file"
                id="files"
                multiple
                accept="image/*,.pdf"
                onChange={(event) => onUpload(event.target.files)}
                style={{ display: 'none' }}
              />
            </Layout.PressableRow>
            <Spacer.Vertical size={16} />
            <Layout.PressableRow
              onClick={() => onRemove()}
              border={[1, 'solid', 'destructive']}
              bg={'destructiveXLight'}
              py={4}
              px
              radius={5}
            >
              <Layout.Row align>
                <Icon name="close-circle-outline" size={16} color={theme.colors.destructiveDark} />
                <Spacer.Horizontal size={8} />
                <OpenSans.Primary color={'destructiveDark'}>
                  {getTranslation('Remove Icon')}
                </OpenSans.Primary>
              </Layout.Row>
            </Layout.PressableRow>
          </Layout.Column>
        </Layout.Row>
      </Layout.Column>
    </Layout.Row>
  );
};
