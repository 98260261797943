import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

const FeatureRow: React.FC<{
  enabled?: boolean;
  feature: string;
  inverse?: boolean;
}> = ({ enabled, feature, inverse }) => {
  let color = 'primary';
  if (inverse) {
    color = 'inverse';
  }
  if (!enabled) {
    color = 'secondary';
  }

  return (
    <Layout.Row align py={4}>
      <Icon
        name={enabled ? 'checkmark-circle' : 'close-circle-outline'}
        size={24}
        color={enabled ? theme.colors.success : theme.colors.secondary}
      />
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary
        size="s-16"
        color={color}
        weight={enabled ? 'bold-700' : 'regular-400'}
        grow
      >
        {getTranslation(feature)}
      </OpenSans.Primary>
    </Layout.Row>
  );
};

export const PlanCard: React.FC<{
  title: string;
  description: string;
  price: string;
  features: {
    enabled: string[];
    disabled: string[];
  };
  onPress: () => void;
  perUser?: boolean;
  cta: string;
  inverse?: boolean;
}> = ({ title, description, price, features, inverse, cta, perUser, onPress }) => {
  return (
    <Layout.Column
      bg={inverse ? 'brandPrimaryDark' : 'white'}
      border={inverse ? undefined : [1, 'solid', 'grayLight']}
      radius={16}
      px
      py={16}
      style={{ maxWidth: 350, minWidth: 300 }}
    >
      <Layout.Row>
        <Layout.Row bg={inverse ? 'white' : 'grayXLight'} radius={100} px py={2}>
          <OpenSans.Primary
            size="s-16"
            weight="bold-700"
            color={inverse ? 'brandPrimaryDark' : 'grayDark'}
          >
            {getTranslation(title)}
          </OpenSans.Primary>
        </Layout.Row>
      </Layout.Row>

      <Spacer.Vertical size={16} />

      <OpenSans.Primary size="s-16" weight="light-300" color={inverse ? 'white' : 'secondary'}>
        {getTranslation(description)}
      </OpenSans.Primary>

      <Spacer.Vertical size={16} />

      <Layout.Row align="flex-end">
        <OpenSans.Primary weight="bold-700" size={32} color={inverse ? 'white' : 'primary'}>
          {price}
        </OpenSans.Primary>
        <Spacer.Horizontal size={6} />
        <Layout.Column py={8}>
          {perUser ? (
            <OpenSans.Primary
              weight="light-300"
              color={inverse ? 'white' : 'primary'}
              size={'xs-12'}
            >
              {getTranslation('PER USER')}
            </OpenSans.Primary>
          ) : null}
          <OpenSans.Primary weight="light-300" color={inverse ? 'white' : 'primary'} size={'xs-12'}>
            {getTranslation('PER MONTH')}
          </OpenSans.Primary>
        </Layout.Column>
      </Layout.Row>

      <Spacer.Vertical size={16} />

      <Layout.Row style={{ width: '100%' }}>
        <Layout.PressableColumn
          bg={inverse ? 'white' : 'brandPrimaryXLight'}
          border={inverse ? undefined : [1, 'solid', 'brandPrimary']}
          px
          py
          radius={100}
          center
          onClick={() => onPress()}
          grow
        >
          <OpenSans.Pressable weight="bold-700">{getTranslation(cta)}</OpenSans.Pressable>
        </Layout.PressableColumn>
      </Layout.Row>

      <Spacer.Vertical size={16} />

      {
        <Layout.Column>
          {features.enabled.map((feature) => (
            <FeatureRow key={feature} enabled feature={feature} inverse={inverse} />
          ))}
          {features.disabled.map((feature) => (
            <FeatureRow key={feature} feature={feature} inverse={inverse} />
          ))}
        </Layout.Column>
      }
    </Layout.Column>
  );
};
