import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { SurveyRow } from './SurveyRow';

export type SurveyOption = {
  title: string;
  subtitle?: string;
  success?: string;
};

export type SurveyStepProps = {
  header: string;
  text: string;
  options: SurveyOption[];
  selectedOptions: string[];
  multiSelect: boolean;
  onOptionSelected: (options: string[]) => void;
};

export const Survey: React.FC<SurveyStepProps> = ({
  header,
  text,
  options,
  multiSelect,
  selectedOptions = [],
  onOptionSelected,
}) => {
  const [surveyResult, setSurveyResult] = React.useState<string[]>(selectedOptions);

  const toggleSurveyResult = (option: SurveyOption) => {
    if (multiSelect) {
      setSurveyResult((prev) => {
        let resultUpdate;
        if (prev.includes(option.title)) {
          resultUpdate = prev.filter((item) => item !== option.title);
        } else {
          resultUpdate = [...prev, option.title];
        }

        onOptionSelected(resultUpdate);
        return resultUpdate;
      });
    } else {
      onOptionSelected([option.title]);
      setSurveyResult([option.title]);
    }
  };

  const successMessage = options.find(
    (option) => surveyResult.includes(option.title) && option.success,
  )?.success;

  return (
    <Layout.Column>
      <OpenSans.Primary weight="bold-700" size={24}>
        {header}
      </OpenSans.Primary>
      <Spacer.Vertical size={4} />
      <OpenSans.Secondary size={'s-16'}>{text}</OpenSans.Secondary>

      <Spacer.Vertical size={36} />

      {options.map((option: SurveyOption, index: number) => (
        <SurveyRow
          key={index}
          selected={!!surveyResult.includes(option.title)}
          title={option.title}
          subtitle={option.subtitle}
          onPress={() => {
            toggleSurveyResult(option);
          }}
        />
      ))}

      <Layout.Column
        style={{
          opacity: successMessage ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        {successMessage ? (
          <Layout.Row bg="successXLight" px py radius border={[2, 'solid', 'successDark']} align>
            <Icon name="shield-checkmark" size={28} color={theme.colors.successDark} />

            <Spacer.Horizontal size={12} />

            <OpenSans.Primary size="s-16">{successMessage}</OpenSans.Primary>
          </Layout.Row>
        ) : null}
      </Layout.Column>
    </Layout.Column>
  );
};
