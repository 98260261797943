import { Layout, Spacer, zIndex } from '@easy-expense/ui-web-core';
import { FixMe } from '@easy-expense/utils-typescript';
import React from 'react';

export const Modal: React.FC<
  React.PropsWithChildren<{
    onClose?: () => void;
    setShowModal: (showModal: boolean) => void;
    showModal?: boolean;
    footer?: React.ReactNode;
    px?: number;
    py?: number;
  }>
> = ({ showModal, setShowModal, children, footer, px = 32, py = 32 }) => {
  if (!showModal) {
    return null;
  }
  return (
    <Layout.Column
      center
      bg="shadow"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: zIndex.ModalOverlay,
      }}
      onClick={() => setShowModal(false)}
    >
      <Layout.Column
        radius
        center
        bg="white"
        style={{
          maxHeight: '90%',
        }}
        onClick={(e: FixMe) => e.stopPropagation()}
      >
        <Layout.Column px={px}>
          <Spacer.Vertical size={py} />
          {children}
          <Spacer.Vertical size={footer ? 0 : py} />
        </Layout.Column>
        <Layout.Column style={{ width: '100%' }}>{footer}</Layout.Column>
      </Layout.Column>
    </Layout.Column>
  );
};
