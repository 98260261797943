import { useIsAdminRole } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

export enum SettingsView {
  workspace = 'workspace',
  account = 'account',
  categories = 'categories',
  clients = 'clients',
  locations = 'locations',
  billing = 'billing',
}

export const SettingsHeader: React.FC<{
  settingsView: SettingsView;
  setSettingsView: (settingsView: SettingsView) => void;
}> = ({ setSettingsView, settingsView }) => {
  const isAdmin = useIsAdminRole();
  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={32} weight="bold-700">
          {getTranslation('Settings')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={16} />

      <Layout.Row>
        {isAdmin ? (
          <>
            <Layout.Column>
              <Layout.PressableRow
                onClick={() => setSettingsView(SettingsView.workspace)}
                bg={settingsView === SettingsView.workspace ? 'brandPrimary' : 'inputBackground'}
                border={
                  settingsView === SettingsView.workspace
                    ? [1, 'solid', 'brandPrimary']
                    : [1, 'solid', 'inputBorder']
                }
                py={4}
                px
                radius={5}
                align
              >
                <Icon
                  name="cog-outline"
                  size={16}
                  color={
                    settingsView === SettingsView.workspace
                      ? theme.colors.white
                      : theme.colors.primary
                  }
                />
                <Spacer.Horizontal size={8} />
                <OpenSans.Primary
                  color={settingsView === SettingsView.workspace ? 'white' : 'primary'}
                >
                  {getTranslation('Workspace')}
                </OpenSans.Primary>
              </Layout.PressableRow>
            </Layout.Column>
            <Spacer.Horizontal size={16} />
          </>
        ) : null}

        <Layout.Column>
          <Layout.PressableRow
            onClick={() => setSettingsView(SettingsView.account)}
            bg={settingsView === SettingsView.account ? 'brandPrimary' : 'inputBackground'}
            border={
              settingsView === SettingsView.account
                ? [1, 'solid', 'brandPrimary']
                : [1, 'solid', 'inputBorder']
            }
            py={4}
            px
            radius={5}
            align
          >
            <Icon
              name="person-outline"
              size={16}
              color={
                settingsView === SettingsView.account ? theme.colors.white : theme.colors.primary
              }
            />
            <Spacer.Horizontal size={8} />
            <OpenSans.Primary color={settingsView === SettingsView.account ? 'white' : 'primary'}>
              {getTranslation('Account')}
            </OpenSans.Primary>
          </Layout.PressableRow>
        </Layout.Column>
        <Spacer.Horizontal size={16} />

        {isAdmin ? (
          <>
            <Layout.Column>
              <Layout.PressableRow
                onClick={() => setSettingsView(SettingsView.categories)}
                bg={settingsView === SettingsView.categories ? 'brandPrimary' : 'inputBackground'}
                border={
                  settingsView === SettingsView.categories
                    ? [1, 'solid', 'brandPrimary']
                    : [1, 'solid', 'inputBorder']
                }
                py={4}
                px
                radius={5}
                align
              >
                <Icon
                  name="pricetags-outline"
                  size={16}
                  color={
                    settingsView === SettingsView.categories
                      ? theme.colors.white
                      : theme.colors.primary
                  }
                />
                <Spacer.Horizontal size={8} />
                <OpenSans.Primary
                  color={settingsView === SettingsView.categories ? 'white' : 'primary'}
                >
                  {getTranslation('Categories')}
                </OpenSans.Primary>
              </Layout.PressableRow>
            </Layout.Column>
            <Spacer.Horizontal size={16} />
          </>
        ) : null}

        {isAdmin ? (
          <>
            <Layout.Column>
              <Layout.PressableRow
                onClick={() => setSettingsView(SettingsView.clients)}
                bg={settingsView === SettingsView.clients ? 'brandPrimary' : 'inputBackground'}
                border={
                  settingsView === SettingsView.clients
                    ? [1, 'solid', 'brandPrimary']
                    : [1, 'solid', 'inputBorder']
                }
                py={4}
                px
                radius={5}
                align
              >
                <Icon
                  name="pricetags-outline"
                  size={16}
                  color={
                    settingsView === SettingsView.clients
                      ? theme.colors.white
                      : theme.colors.primary
                  }
                />
                <Spacer.Horizontal size={8} />
                <OpenSans.Primary
                  color={settingsView === SettingsView.clients ? 'white' : 'primary'}
                >
                  {getTranslation('Clients')}
                </OpenSans.Primary>
              </Layout.PressableRow>
            </Layout.Column>
            <Spacer.Horizontal size={16} />
          </>
        ) : null}
        {isAdmin ? (
          <>
            <Layout.Column>
              <Layout.PressableRow
                onClick={() => setSettingsView(SettingsView.locations)}
                bg={settingsView === SettingsView.locations ? 'brandPrimary' : 'inputBackground'}
                border={
                  settingsView === SettingsView.locations
                    ? [1, 'solid', 'brandPrimary']
                    : [1, 'solid', 'inputBorder']
                }
                py={4}
                px
                radius={5}
                align
              >
                <Icon
                  name="location-outline"
                  size={16}
                  color={
                    settingsView === SettingsView.locations
                      ? theme.colors.white
                      : theme.colors.primary
                  }
                />
                <Spacer.Horizontal size={8} />
                <OpenSans.Primary
                  color={settingsView === SettingsView.locations ? 'white' : 'primary'}
                >
                  {getTranslation('Locations')}
                </OpenSans.Primary>
              </Layout.PressableRow>
            </Layout.Column>
            <Spacer.Horizontal size={16} />
          </>
        ) : null}
        {isAdmin ? (
          <Layout.Column>
            <Layout.PressableRow
              onClick={() => setSettingsView(SettingsView.billing)}
              bg={settingsView === SettingsView.billing ? 'brandPrimary' : 'inputBackground'}
              border={
                settingsView === SettingsView.billing
                  ? [1, 'solid', 'brandPrimary']
                  : [1, 'solid', 'inputBorder']
              }
              py={4}
              px
              radius={5}
              align
            >
              <Icon
                name="card-outline"
                size={16}
                color={
                  settingsView === SettingsView.billing ? theme.colors.white : theme.colors.primary
                }
              />
              <Spacer.Horizontal size={8} />
              <OpenSans.Primary color={settingsView === SettingsView.billing ? 'white' : 'primary'}>
                {getTranslation('Billing')}
              </OpenSans.Primary>
            </Layout.PressableRow>
          </Layout.Column>
        ) : null}
      </Layout.Row>
    </Layout.Column>
  );
};
