import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

const SurveyRowIcon: React.FC<{ selected: boolean; emoji?: string }> = ({ selected, emoji }) => {
  if (selected) {
    return (
      <Icon
        name={selected ? 'checkmark-circle' : 'checkmark-circle-outline'}
        size={28}
        color={selected ? theme.colors.brandPrimary : theme.colors.primary}
      />
    );
  }

  if (emoji) {
    return <OpenSans.Primary size={24}>{emoji}</OpenSans.Primary>;
  }

  return (
    <Icon
      name={selected ? 'checkmark-circle' : 'checkmark-circle-outline'}
      size={28}
      color={selected ? theme.colors.brandPrimary : theme.colors.primary}
    />
  );
};

export const SurveyRow: React.FC<{
  selected: boolean;
  title: string;
  emoji?: string;
  subtitle?: string;
  onPress: () => void;
}> = ({ selected, title, subtitle, onPress, emoji }) => {
  return (
    <Layout.Column py={selected ? 0 : 1} px={selected ? 0 : 1}>
      <Layout.PressableRow
        px
        py
        bg={selected ? 'brandPrimaryLight' : 'white'}
        border={[selected ? 2 : 1, 'solid', selected ? 'brandPrimary' : 'gray']}
        radius
        align
        onClick={onPress}
      >
        <SurveyRowIcon selected={selected} emoji={emoji} />
        <Spacer.Horizontal />
        <Layout.Column>
          <Layout.Row>
            <OpenSans.Primary weight={selected ? 'bold-700' : 'regular-400'}>
              {title}
            </OpenSans.Primary>
          </Layout.Row>
          {subtitle ? (
            <Layout.Row>
              <OpenSans.Secondary style={{ fontSize: 14 }}>{subtitle}</OpenSans.Secondary>
            </Layout.Row>
          ) : null}
        </Layout.Column>
      </Layout.PressableRow>
      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
