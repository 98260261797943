import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';

import { Button } from '../Button.components';
import { Modal } from '../Shared/Modal.component';

export const FailedStatementParsingModal: React.FC<
  React.PropsWithChildren<{
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
  }>
> = ({ showModal, setShowModal }) => {
  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      footer={
        <Layout.Column
          style={{
            borderTop: `2px solid ${theme.colors.grayXLight}`,
          }}
        >
          <Layout.Row px={32} py={24} justify="flex-end">
            <Button.Primary onClick={() => setShowModal(false)} content={getTranslation('Ok')} />
          </Layout.Row>
        </Layout.Column>
      }
    >
      <Layout.Column style={{ maxWidth: 700 }}>
        <Layout.Row px py align>
          <Icon name="warning" size={32} color={theme.colors.destructiveDark} />
          <OpenSans.Custom px size="16" weight="bold-700" color="destructive">
            {getTranslation('Invalid CSV format.')}
          </OpenSans.Custom>
        </Layout.Row>
        <Layout.Row px py>
          <OpenSans.Custom size="16" weight="bold-700" color="destructive">
            <span>
              {getTranslation('For more information, read the ')}
              <a href="https://easyexpense.document360.io/docs/reconciliation-reports">
                automic reconciliation reports guide.
              </a>
            </span>
          </OpenSans.Custom>
        </Layout.Row>
      </Layout.Column>
    </Modal>
  );
};
