export type TeamPlanCode = 'TEAMS_PRO' | 'TEAMS_BASIC' | 'TEAMS_STARTER';
export type BusinessPlanCode = 'ANNUAL_7DAYTRIAL_STARTER' | 'MONTHLY_7DAYTRIAL_STARTER';

export type PaddleItemsPricings<T extends TeamPlanCode | BusinessPlanCode> = Record<T, string>;

export const paddleTeamItemsProd: PaddleItemsPricings<TeamPlanCode> = {
  TEAMS_STARTER: 'pri_01j29wsd5zxa6fcxf8gh4bg86c',
  TEAMS_PRO: 'pri_01j29wep1gn000gxaa99emx8zh',
  TEAMS_BASIC: 'pri_01j1dg6v56b7f2nt9wbzpemtc2',
};

export const paddleTeamItemsSandbox: PaddleItemsPricings<TeamPlanCode> = {
  TEAMS_STARTER: 'pri_01j2c87ra77w1skyere0vsqpxx',
  TEAMS_PRO: 'pri_01j2c88f76zcb06ssxksfvbsp0',
  TEAMS_BASIC: 'pri_01j2c897nbbnfd3xj6ans7503t',
};

export const paddleBusinessItemsProd: PaddleItemsPricings<BusinessPlanCode> = {
  ANNUAL_7DAYTRIAL_STARTER: 'pri_01jdnzjqyjqmk1tzm4x78bx6yp',
  MONTHLY_7DAYTRIAL_STARTER: 'pri_01jd0pzqxz92xb6b62zbmw20fx',
};

export const paddleBusinessItemsSandbox: PaddleItemsPricings<BusinessPlanCode> = {
  ANNUAL_7DAYTRIAL_STARTER: 'pri_01jft7ve17qxysapcrpm3sjkvk',
  MONTHLY_7DAYTRIAL_STARTER: 'pri_01jft7vyh366gwqq1anby2tpcd',
};

export const paddleClientTokenProd = 'live_845f6bbf84676f9f51709dd1664';
export const paddleClientTokenSandbox = 'test_233ce5f6334d8e4b9a658161096';
