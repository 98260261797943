import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { Button } from '../Button.components';
import { LabelTextField } from '../LabelTextField.component';
import { Modal } from '../Shared/Modal.component';

export const ReconcileModal: React.FC<
  React.PropsWithChildren<{
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    onReconcile: () => void;
    dateHeader: string;
    setDateHeader: (date: string) => void;
    totalHeader: string;
    setTotalHeader: (total: string) => void;
    headers: string[];
  }>
> = (props) => {
  const {
    showModal,
    setShowModal,
    onReconcile,
    dateHeader,
    setDateHeader,
    totalHeader,
    setTotalHeader,
    headers,
  } = props;

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      footer={
        <Layout.Column
          style={{
            borderTop: `2px solid ${theme.colors.grayXLight}`,
          }}
        >
          <Layout.Row px={32} py={24} justify="flex-end">
            <Button.Primary onClick={onReconcile} content={getTranslation('Reconcile')} />
          </Layout.Row>
        </Layout.Column>
      }
    >
      <Layout.Column style={{ maxWidth: 700 }}>
        <OpenSans.Primary size={36} weight="bold-700">
          {getTranslation('Reconcile Statement')}
        </OpenSans.Primary>

        <Spacer.Vertical size={24} />

        <Layout.Row
          align
          style={{
            borderTop: `1px solid ${theme.colors.inputBorder}`,
          }}
          px
          py
        >
          <Layout.Column
            style={{
              maxWidth: 350,
            }}
          >
            <Layout.Row align>
              <OpenSans.Primary size={16} weight="bold-700">
                {getTranslation('Date')}
              </OpenSans.Primary>
            </Layout.Row>
            <Layout.Row align>
              <OpenSans.Secondary size={16}>
                {getTranslation(
                  'Pick the column from your statement that corpresponds to the date of the receipts',
                )}
              </OpenSans.Secondary>
            </Layout.Row>
          </Layout.Column>
          <Spacer.Horizontal size={24} />
          <Layout.Column>
            <LabelTextField label="" active={false}>
              <Layout.Row
                style={{
                  width: '100%',
                  fontFamily: 'Poppins_Light',
                  position: 'relative',
                }}
              >
                <Icon name="calendar-outline" size={20} style={{ paddingRight: 10 }} />
                <select
                  value={dateHeader}
                  onChange={(e) => setDateHeader(e.target.value)}
                  style={{
                    border: 'none',
                    width: '100%',
                    background: 'transparent',
                    zIndex: 1,
                  }}
                >
                  <option value="">Select date column</option>
                  {headers.map((header) => (
                    <option key={header} value={header}>
                      {header}
                    </option>
                  ))}
                </select>

                <Icon name="chevron-down" size={20} style={{ paddingLeft: 10 }} />
              </Layout.Row>
            </LabelTextField>
          </Layout.Column>
        </Layout.Row>

        <Layout.Row
          align
          style={{
            borderTop: `1px solid ${theme.colors.inputBorder}`,
          }}
          px
          py
        >
          <Layout.Column
            style={{
              maxWidth: 350,
            }}
          >
            <Layout.Row align>
              <OpenSans.Primary size={16} weight="bold-700">
                {getTranslation('Total')}
              </OpenSans.Primary>
            </Layout.Row>
            <Layout.Row align>
              <OpenSans.Secondary size={16}>
                {getTranslation(
                  'Pick the column from your statement that corpresponds to the expense total of the receipts',
                )}
              </OpenSans.Secondary>
            </Layout.Row>
          </Layout.Column>
          <Spacer.Horizontal size={24} />
          <Layout.Column>
            <LabelTextField label="" active={false}>
              <Layout.Row
                style={{
                  width: '100%',
                  fontFamily: 'Poppins_Light',
                  position: 'relative',
                }}
              >
                <Icon name="calculator-outline" size={20} style={{ paddingRight: 10 }} />
                <select
                  value={totalHeader}
                  onChange={(e) => setTotalHeader(e.target.value)}
                  style={{
                    border: 'none',
                    width: '100%',
                    background: 'transparent',
                    zIndex: 1,
                  }}
                >
                  <option value="">Select total column</option>
                  {headers.map((header) => (
                    <option key={header} value={header}>
                      {header}
                    </option>
                  ))}
                </select>

                <Icon name="chevron-down" size={20} style={{ paddingLeft: 10 }} />
              </Layout.Row>
            </LabelTextField>
          </Layout.Column>
        </Layout.Row>
      </Layout.Column>
    </Modal>
  );
};
