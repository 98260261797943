import React from 'react';

import { AutoCompleteInputParams, AutoCompleteValue } from './AutoCompleteTypes';
import { StaticDropDown } from './StaticDropDown.component';

export const Dropdown: React.FC<React.PropsWithChildren<AutoCompleteInputParams>> = (params) => {
  const initialKey = params.selectedKey;
  const [value, setValue] = React.useState<AutoCompleteValue | null>(
    params.autoCompleteValues.find((value) => value.key === initialKey) || null,
  );

  const prevValueRef = React.useRef<AutoCompleteValue | null>(value);

  React.useEffect(() => {
    setValue(params.autoCompleteValues.find((value) => value.key === initialKey) || null);
  }, [params.selectedKey]);

  function onInputFocus() {
    setValue(null);
    prevValueRef.current = value;
  }

  function onInputBlur() {
    setValue(structuredClone(prevValueRef.current));
  }

  function onSelectValue(updateValue: AutoCompleteValue | null) {
    const exists = params.autoCompleteValues.some(
      (entity) => updateValue?.value.name && entity.value.name === updateValue?.value.name,
    );
    if (!exists) {
      return;
    }
    prevValueRef.current = updateValue;
    params.onSelect(updateValue);
  }

  return (
    <StaticDropDown
      {...params}
      allowCreateNew={false}
      onSelect={onSelectValue}
      onInputBlur={onInputBlur}
      onInputFocus={onInputFocus}
      value={value}
    />
  );
};
