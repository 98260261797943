import {
  canChangeUserRole,
  useCachedMembers,
  useCurrentWorkspace,
  useWorkspaceStore,
} from '@easy-expense/data-firestore-client';
import {
  UserRole,
  UserRoleSchema,
  UserStatusSchema,
  WorkspaceMember,
} from '@easy-expense/data-schema-v2';
import { getUserDisplayName } from '@easy-expense/utils-shared';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../firebase/app';

export type WorkspaceRow = {
  key?: string;
  userID?: string;
  displayName: string;
  role: string;
  email: string;
  status: status;
  lastActive: number;
  updateOptions?: UserRole[];
};

export enum status {
  Active = 'Active',
  Invited = 'Invited',
}

function getMembershipRole(params: { member: WorkspaceMember; owner: string }) {
  const { member, owner } = params;
  if (member.userID === owner) {
    return 'Owner';
  }
  return member.role.charAt(0).toUpperCase() + member.role.slice(1);
}

export function useWorkspaceRows() {
  const members = useCachedMembers();

  const workspace = useCurrentWorkspace();

  const organizationMembers = useWorkspaceStore((s) => s.organizationMembers);
  const [user] = useAuthState(auth);

  return React.useMemo(() => {
    const self = members?.find((mem) => mem.userID === user?.uid);

    const res: WorkspaceRow[] = [
      ...members.map((member) => {
        const orgMember = organizationMembers[member.userID];
        const updateOptions =
          orgMember?.status === UserStatusSchema.Values.active &&
          self &&
          canChangeUserRole(member, self, workspace?.createdBy)
            ? UserRoleSchema.options.filter((el) => el !== member.role)
            : [];

        return {
          key: member.key,
          userID: member.userID,
          displayName: getUserDisplayName(member),
          role: getMembershipRole({ member, owner: workspace?.createdBy ?? '' }),
          email: member?.email ?? 'No Email',
          status: orgMember?.status === 'invited' ? status.Invited : status.Active,
          lastActive: member?.updatedAt ?? 0,
          updateOptions,
        };
      }),
    ];

    return res;
  }, [members, organizationMembers]);
}
