import {
  useCurrentWorkspace,
  useModifyWorkspace,
  useWorkspaceKeysStore,
} from '@easy-expense/data-firestore-client';
import { TaxRate } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox } from '@mui/material';
import React from 'react';

import { LabelTextField } from '../../LabelTextField.component';

type CustomizableTaxRate = Omit<TaxRate, 'rate'> & { rate: number | string };

export const WorkspaceTaxRate: React.FC = () => {
  const workspace = useCurrentWorkspace();

  const [taxRateOne, setTaxRateOne] = React.useState<CustomizableTaxRate | undefined>(
    workspace?.settings.taxRates[0]
      ? { ...workspace.settings.taxRates[0], rate: workspace.settings.taxRates[0].rate * 100 }
      : undefined,
  );

  const [taxRateTwo, setTaxRateTwo] = React.useState<CustomizableTaxRate | undefined>(
    workspace?.settings.taxRates[1]
      ? { ...workspace.settings.taxRates[1], rate: workspace.settings.taxRates[1].rate * 100 }
      : undefined,
  );

  const currentWorkspacePath = useWorkspaceKeysStore((s) => s.currentWorkspacePath());
  const [updateWorkspace] = useModifyWorkspace(currentWorkspacePath, 'update');

  const convertToTaxRate = (customizedRate: CustomizableTaxRate): TaxRate => {
    const parsedRate = parseFloat(customizedRate.rate.toString() ?? '0');
    const taxRate = {
      name: customizedRate.name,
      tracked: customizedRate.tracked,
      rate: isNaN(parsedRate) ? 0 : parsedRate / 100,
    };
    return taxRate;
  };

  function onChange({
    taxRateOne,
    taxRateTwo,
  }: {
    taxRateOne: CustomizableTaxRate | undefined;
    taxRateTwo: CustomizableTaxRate | undefined;
  }) {
    if (!workspace?.settings) {
      return;
    }

    const taxRates = [];
    if (taxRateOne) {
      taxRates.push(convertToTaxRate(taxRateOne));
    }
    if (taxRateTwo) {
      taxRates.push(convertToTaxRate(taxRateTwo));
    }

    updateWorkspace({
      settings: {
        ...workspace.settings,
        taxRates,
      },
    });
  }

  return (
    <Layout.Column>
      <Layout.Row
        align
        style={{
          borderTop: `1px solid ${theme.colors.inputBorder}`,
        }}
        px
        py
      >
        <Layout.Column
          style={{
            maxWidth: 350,
          }}
        >
          <Layout.Row align>
            <OpenSans.Primary size={16} weight="bold-700">
              {getTranslation('Enable Sales Tax')}
            </OpenSans.Primary>
          </Layout.Row>
          <Layout.Row align>
            <OpenSans.Secondary size={16}>
              {getTranslation('Add a tracked sales tax to expenses and reports')}
            </OpenSans.Secondary>
          </Layout.Row>
        </Layout.Column>
        <Spacer.Horizontal size={24} />
        <Layout.Column>
          <Layout.Row>
            <Checkbox
              style={{ padding: 0 }}
              checked={taxRateOne?.tracked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const tracked = event.target.checked;
                const rate = parseFloat(taxRateOne?.rate.toString() ?? '0');
                const name = taxRateOne?.name ?? 'Tax';
                const updatedTaxRateOne = {
                  tracked,
                  rate,
                  name,
                };
                const updatedTaxRateTwo = taxRateTwo;
                if (!tracked && updatedTaxRateTwo) {
                  updatedTaxRateTwo.tracked = false;
                }
                onChange({ taxRateOne: updatedTaxRateOne, taxRateTwo: updatedTaxRateTwo });
              }}
            />
            <Spacer.Horizontal size={12} />
            {taxRateOne?.tracked ? (
              <>
                <LabelTextField
                  label="Tax Rate Name"
                  disabled={!taxRateOne?.tracked}
                  styles={{ backgroundColor: theme.colors.inputBackground }}
                >
                  <OpenSans.Input
                    name="taxRateOneName"
                    value={taxRateOne?.name}
                    disabled={!taxRateOne?.tracked}
                    type="text"
                    weight="bold-700"
                    placeholder="Tax"
                    grow
                    size={18}
                    style={{ outline: 'none', width: '80%' }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (taxRateOne) {
                        setTaxRateOne({ ...taxRateOne, name: event.target.value });
                      }
                    }}
                    onBlur={() => onChange({ taxRateOne, taxRateTwo })}
                    autoComplete="off"
                  />
                </LabelTextField>
                <Spacer.Horizontal size={12} />
                <LabelTextField
                  label="Tax Rate"
                  disabled={!taxRateOne?.tracked}
                  styles={{ backgroundColor: theme.colors.inputBackground }}
                >
                  <Layout.Row style={{ width: '100%' }} align>
                    <OpenSans.Primary style={{ marginRight: 6 }} weight="light-300">
                      {'%'}
                    </OpenSans.Primary>
                    <Layout.Row grow>
                      <OpenSans.Input
                        value={taxRateOne?.rate.toString()}
                        type="number"
                        weight="light-300"
                        placeholder={'0.00'}
                        disabled={!taxRateOne?.tracked}
                        size={18}
                        style={{ flexGrow: 1, outline: 'none', width: '100%' }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (taxRateOne) {
                            setTaxRateOne({ ...taxRateOne, rate: event.target.value });
                          }
                        }}
                        onBlur={() => onChange({ taxRateOne, taxRateTwo })}
                      />
                    </Layout.Row>
                  </Layout.Row>
                </LabelTextField>
              </>
            ) : null}
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>
      {taxRateOne?.tracked ? (
        <Layout.Row align px py style={{ borderTop: `1px solid ${theme.colors.inputBorder}` }}>
          <Layout.Column
            style={{
              maxWidth: 350,
            }}
          >
            <Layout.Row align>
              <OpenSans.Primary size={16} weight="bold-700">
                {getTranslation('Enable Additional Sales Tax')}
              </OpenSans.Primary>
            </Layout.Row>
            <Layout.Row align>
              <OpenSans.Secondary size={16}>
                {getTranslation('Add an additional tracked sales tax to expenses and reports')}
              </OpenSans.Secondary>
            </Layout.Row>
          </Layout.Column>
          <Spacer.Horizontal size={24} />

          <Layout.Column>
            <Layout.Row>
              <Checkbox
                style={{ padding: 0 }}
                checked={taxRateTwo?.tracked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const tracked = event.target.checked;
                  const rate = parseFloat(taxRateTwo?.rate.toString() ?? '0');
                  const name = taxRateTwo?.name ?? 'Tax';
                  const updatedTaxRateTwo = {
                    tracked,
                    rate,
                    name,
                  };
                  onChange({ taxRateTwo: updatedTaxRateTwo, taxRateOne });
                }}
              />
              <Spacer.Horizontal size={12} />
              {taxRateTwo?.tracked ? (
                <>
                  <LabelTextField
                    label=""
                    disabled={!taxRateTwo?.tracked}
                    styles={{ backgroundColor: theme.colors.inputBackground }}
                  >
                    <OpenSans.Input
                      name="taxRateTwoName"
                      value={taxRateTwo?.name}
                      disabled={!taxRateTwo?.tracked}
                      type="text"
                      weight="bold-700"
                      placeholder="Tax"
                      grow
                      size={18}
                      style={{ outline: 'none', width: '80%' }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (taxRateTwo) {
                          setTaxRateTwo({ ...taxRateTwo, name: event.target.value });
                        }
                      }}
                      onBlur={() => onChange({ taxRateOne, taxRateTwo })}
                      autoComplete="off"
                    />
                  </LabelTextField>
                  <Spacer.Horizontal size={12} />
                  <LabelTextField
                    label=""
                    disabled={!taxRateTwo?.tracked}
                    styles={{ backgroundColor: theme.colors.inputBackground }}
                  >
                    <Layout.Row style={{ width: '100%' }} align>
                      <OpenSans.Primary style={{ marginRight: 6 }} weight="light-300">
                        {'%'}
                      </OpenSans.Primary>
                      <Layout.Row grow>
                        <OpenSans.Input
                          value={taxRateTwo?.rate.toString()}
                          type="number"
                          weight="light-300"
                          placeholder={'0.00'}
                          disabled={!taxRateTwo?.tracked}
                          size={18}
                          style={{ flexGrow: 1, outline: 'none', width: '100%' }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (taxRateTwo) {
                              setTaxRateTwo({ ...taxRateTwo, rate: event.target.value });
                            }
                          }}
                          onBlur={() => onChange({ taxRateOne, taxRateTwo })}
                        />
                      </Layout.Row>
                    </Layout.Row>
                  </LabelTextField>
                </>
              ) : null}
            </Layout.Row>
          </Layout.Column>
        </Layout.Row>
      ) : null}
    </Layout.Column>
  );
};
