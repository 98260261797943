import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { DateField } from '../Expense/Edit/DateField.component';
import { Dropdown } from '../Shared/Dropdown/Dropdown.component';

function getLastDayOfMonth(year: string, month: string) {
  const yearNumber = parseInt(year);
  const monthNumber = parseInt(month);
  const lastDay = new Date(yearNumber, monthNumber, 0).getDate();
  return lastDay.toString().padStart(2, '0');
}

export type Range = 'custom' | 'all time' | 'this month' | 'last month' | 'this year' | 'last year';
const RANGE_SUGGESTIONS: { key: Range; value: { name: string; iconicIcon: 'calendar-outline' } }[] =
  [
    { key: 'all time', value: { name: 'All Time', iconicIcon: 'calendar-outline' } },
    { key: 'this month', value: { name: 'This Month', iconicIcon: 'calendar-outline' } },
    { key: 'last month', value: { name: 'Last Month', iconicIcon: 'calendar-outline' } },
    { key: 'this year', value: { name: 'This Year', iconicIcon: 'calendar-outline' } },
    { key: 'last year', value: { name: 'Last Year', iconicIcon: 'calendar-outline' } },
    { key: 'custom', value: { name: 'Custom', iconicIcon: 'calendar-outline' } },
  ];

type DateFilters = {
  startDate: string | null;
  endDate: string | null;
  range?: Range;
};

const getRange = (dateFilters: DateFilters): Range => {
  if (dateFilters.range) {
    return dateFilters.range;
  }
  if (!!dateFilters.startDate || !!dateFilters.endDate) {
    return 'custom';
  }
  return 'all time';
};

const setDatesBasedOnRange = ({
  range,
  setDateFilters,
}: {
  range: Range;
  setDateFilters: (filters: { startDate: string | null; endDate: string | null }) => void;
}) => {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  const thisYear = year.toString();
  const thisMonth = (month + 1).toString().padStart(2, '0');
  const lastDayOfMonth = getLastDayOfMonth(thisYear, thisMonth);
  const lastMonth = month === 0 ? '12' : month.toString().padStart(2, '0');
  const lastMonthYear = month === 0 ? (year - 1).toString() : thisYear;
  const lastMonthLastDay = getLastDayOfMonth(lastMonthYear, lastMonth);
  const lastYear = (year - 1).toString();

  switch (range) {
    case 'all time':
      setDateFilters({
        startDate: null,
        endDate: null,
      });
      break;
    case 'this year':
      setDateFilters({
        startDate: `${thisYear}-01-01`,
        endDate: `${thisYear}-12-31`,
      });
      break;
    case 'this month':
      setDateFilters({
        startDate: `${thisYear}-${thisMonth}-01`,
        endDate: `${thisYear}-${thisMonth}-${lastDayOfMonth}`,
      });
      break;
    case 'last month':
      setDateFilters({
        startDate: `${lastMonthYear}-${lastMonth}-01`,
        endDate: `${lastMonthYear}-${lastMonth}-${lastMonthLastDay}`,
      });
      break;
    case 'last year':
      setDateFilters({
        startDate: `${lastYear}-01-01`,
        endDate: `${lastYear}-12-31`,
      });
      break;
    default:
      break;
  }
};

export const ExportDateFilter: React.FC<{
  dateFilters: DateFilters;
  setDateFilters: (filters: { startDate: string | null; endDate: string | null }) => void;
}> = ({ dateFilters, setDateFilters }) => {
  const [range, setRange] = React.useState<Range>(getRange(dateFilters));

  React.useEffect(() => {
    const range = getRange(dateFilters);
    setRange(range);
  }, [dateFilters]);

  React.useEffect(() => {
    setDatesBasedOnRange({ range, setDateFilters });
  }, [range]);

  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Date')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <Dropdown
          header={getTranslation('Include Transactions For')}
          placeholder={''}
          selectedKey={range}
          autoCompleteValues={RANGE_SUGGESTIONS}
          onSelect={(c) => {
            if (c?.key) {
              setRange(c.key as Range);
            }
          }}
          error={false}
          iconicIcon="calendar-outline"
        />
        <Spacer.Horizontal />
        <DateField
          key="startDate"
          label={'Start Date'}
          initialDate={dateFilters.startDate ?? undefined}
          onChange={(date) => {
            if (date !== dateFilters.startDate) {
              setDateFilters({ ...dateFilters, startDate: date ?? null });
            }
          }}
          hideIcons={true}
        />
        <Spacer.Horizontal />
        <DateField
          key="endDate"
          label={'End Date'}
          initialDate={dateFilters.endDate ?? undefined}
          onChange={(date) => {
            if (date !== dateFilters.endDate) {
              setDateFilters({ ...dateFilters, endDate: date ?? null });
            }
          }}
          hideIcons={true}
        />
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
