import { DataExportRequest } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import Checkbox from '@mui/material/Checkbox';

export const ExportSettingComponent: React.FC<{
  dataExport: DataExportRequest;
  setDataExport: (dataExport: DataExportRequest) => void;
}> = ({ dataExport, setDataExport }) => {
  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Settings')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />

      <Layout.Row align>
        <Layout.Column>
          <Checkbox
            style={{ padding: 0 }}
            checked={dataExport.downloadAttachments}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const downloadAttachments = event.target.checked;
              setDataExport({ ...dataExport, downloadAttachments });
            }}
          />
        </Layout.Column>

        <Spacer.Horizontal size={8} />

        <Layout.Column>
          <Layout.Row align>
            <OpenSans.Primary size={16} weight="bold-700">
              {getTranslation('Download Receipt Images Separately')}
            </OpenSans.Primary>
          </Layout.Row>
          <Spacer.Vertical size={4} />
          <Layout.Row align>
            <OpenSans.Secondary size={16}>
              {getTranslation('Receipt links are always in the CSV')}
            </OpenSans.Secondary>
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
