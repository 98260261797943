import { Layout, zIndex } from '@easy-expense/ui-web-core';
import React from 'react';

import { AutoCompleteRow } from './AutoCompleteRow';
import { AutoCompleteValue } from './AutoCompleteTypes';

const HEADER_HEIGHT = 22;
const NO_HEADER_HEIGHT = 6;

export const TopDropdown: React.FC<{
  flipDropDown: boolean;
  showList: boolean;
  autoCompleteValues: AutoCompleteValue[];
  onSelection: (autoComplete: AutoCompleteValue) => void;
  inputValue: string;
  hasHeader: boolean;
}> = ({ flipDropDown, showList, autoCompleteValues, onSelection, inputValue, hasHeader }) => {
  if (!flipDropDown || !showList) {
    return null;
  }

  return (
    <Layout.Column>
      <Layout.Column
        border={!showList ? undefined : [1, 'solid', 'grayLight']}
        style={{
          borderBottomWidth: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.15)',
          position: 'absolute',
          bottom: hasHeader ? -HEADER_HEIGHT : -NO_HEADER_HEIGHT,
          left: 0,
          right: 0,
          zIndex: zIndex.Dropdown,
          flexDirection: 'column-reverse',
          maxHeight: 300,
          overflow: 'scroll',
        }}
        bg="inputBackground"
      >
        <Layout.Column>
          {autoCompleteValues.map((autoCompleteValue: AutoCompleteValue) => {
            return (
              <AutoCompleteRow
                key={autoCompleteValue.key}
                autoCompleteValue={autoCompleteValue}
                onSelection={onSelection}
                inputValue={inputValue}
              />
            );
          })}
        </Layout.Column>
      </Layout.Column>
    </Layout.Column>
  );
};
