import { useCachedReviewTrips } from '@easy-expense/data-firestore-client';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { Button } from '../Button.components';
import { Modal } from '../Shared/Modal.component';

export const TripActions: React.FC = () => {
  return (
    <Layout.Row align>
      <OpenSans.Primary size={32} weight="bold-700">
        {getTranslation('Drives')}
      </OpenSans.Primary>
    </Layout.Row>
  );
};

export const ReceiptOptions: React.FC = () => {
  const reviewTripsCount = useCachedReviewTrips().length;
  const [showModal, setShowModal] = React.useState(false);

  if (reviewTripsCount > 0) {
    return (
      <Layout.Row>
        <Layout.PressableRow
          px={16}
          py
          border={[1, 'solid', 'brandPrimary']}
          radius={8}
          align
          bg="brandPrimaryXLight"
          onClick={() => setShowModal(true)}
        >
          <Icon name="mail-outline" size={24} color={theme.colors.brandPrimary} />
          <Spacer.Horizontal size={16} />
          <Layout.Column>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {reviewTripsCount + ' ' + getTranslation('Drives Ready for Review')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Automatically track drives with GPS')}
            </OpenSans.Primary>
          </Layout.Column>
          <Spacer.Horizontal size={8} />
        </Layout.PressableRow>

        <Modal setShowModal={setShowModal} showModal={showModal}>
          <OpenSans.Primary>
            Review your drives in the mobile app. Web review is coming soon!
          </OpenSans.Primary>

          <Spacer.Vertical size={48} />

          <Button.Primary
            onClick={() => {
              setShowModal(false);
            }}
            content={getTranslation('Continue')}
          />
        </Modal>
      </Layout.Row>
    );
  }

  return (
    <Layout.Row>
      <Layout.Row px={16} py border={[1, 'solid', 'grayLight']} radius={8} align bg="white">
        <Icon name="navigate-circle-outline" size={24} color={theme.colors.gray} />
        <Spacer.Horizontal size={16} />
        <Layout.Column>
          <OpenSans.Primary weight="bold-700" size="s-16">
            {getTranslation('Automatically track drives')}
          </OpenSans.Primary>
          <OpenSans.Primary size="xs-12">
            {getTranslation('Use GPS to auto track with the mobile app')}
          </OpenSans.Primary>
        </Layout.Column>
        <Spacer.Horizontal size={8} />
      </Layout.Row>
    </Layout.Row>
  );
};

export const TripListHeader: React.FC<object> = () => {
  return (
    <Layout.Column>
      <TripActions />
      <Spacer.Vertical size={12} />
      <ReceiptOptions />
      <Spacer.Vertical size={16} />
      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
