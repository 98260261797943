import { Category } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { Checkbox, MenuItem, TextField } from '@mui/material';
import {
  MRT_GlobalFilterTextField,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import React from 'react';

import { UpsertCategory } from './AddNewCategory.component';
import { IconPicker } from './IconPicker.component';
import { Modal } from '../Shared/Modal.component';

export const ExpenseCategoriesTable: React.FC<React.PropsWithChildren<object>> = () => {
  const categories = Data.expenseCategories.use();
  const [showAddCategoryModal, setShowAddCategoryModal] = React.useState(false);
  const [selectedCategoryKey, setSelectedCategoryKey] = React.useState<string | undefined>(
    undefined,
  );

  const columns = React.useMemo<MRT_ColumnDef<Category>[]>(
    () => [
      {
        id: 'key',
        header: '',
        maxSize: 10,
        Cell: ({ row }) => {
          return (
            <Layout.Row style={{ width: 32 }}>
              <Layout.PressableRow
                border={[1, 'solid', 'destructiveDark']}
                align
                center
                style={{ backgroundColor: theme.colors.destructiveXLight, width: 32, height: 32 }}
                radius={100}
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!row.original.key) {
                    return;
                  }
                  Data.expenseCategories.archive(row.original.key);
                }}
              >
                <Icon name="trash-outline" size={16} color={theme.colors.destructiveDark} />
              </Layout.PressableRow>
            </Layout.Row>
          );
        },
      },
      {
        id: 'icon',
        header: getTranslation('Icon'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Icon')}</OpenSans.Secondary>
        ),
        size: 30,
        Cell: ({ row }) => {
          return (
            <Layout.Row>
              <IconPicker
                icon={row.original.value.icon ?? ''}
                onChange={(icon) => {
                  Data.expenseCategories.update(row.original.key, {
                    value: {
                      ...row.original.value,
                      icon,
                    },
                  });
                }}
              />
            </Layout.Row>
          );
        },
      },
      {
        accessorKey: 'value.name',
        header: getTranslation('Category'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Category')}</OpenSans.Secondary>
        ),
        size: 200,
        Cell: ({ row }) => {
          return <OpenSans.Primary weight="bold-700">{row.original.value.name}</OpenSans.Primary>;
        },
      },
      {
        accessorKey: 'value.deductible',
        accessorFn: (row) => (row.value.deductible ? 'tax deductible' : 'non tax deductible'),
        header: getTranslation('Tax Deductible'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Tax Deductible')}</OpenSans.Secondary>
        ),
        muiTableHeadCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <Layout.Column px align="flex-end" justify="flex-end">
              <Checkbox
                checked={row.original.value.deductible ?? undefined}
                onClick={(event) => event.stopPropagation()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const deductible = event.target.checked;

                  Data.expenseCategories.update(row.original.key, {
                    value: {
                      ...row.original.value,
                      deductible,
                    },
                  });
                }}
              />
            </Layout.Column>
          );
        },
        Filter: (props) => {
          return (
            <TextField
              fullWidth
              margin="none"
              onChange={(e) => props.column.setFilterValue(e.target.value || undefined)}
              placeholder="Filter"
              select
              value={props.column.getFilterValue() ?? ''}
              variant="standard"
            >
              <MenuItem value="none">All</MenuItem>
              <MenuItem value="true">Tax Deductible</MenuItem>
              <MenuItem value="false">Not Tax Deductible</MenuItem>
            </TextField>
          );
        },
        filterFn: (row, _id, filterValue) => {
          if (filterValue === 'none') {
            return true;
          }

          const isTrueSet = filterValue === 'true';
          return row.original.value.deductible === isTrueSet;
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: categories,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 25 },
      sorting: [{ desc: false, id: 'value.name' }],
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
    },
    autoResetPageIndex: false,
    autoResetExpanded: false,
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.key ?? '',
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer',
      },
      onClick: () => displayCategoryEditModal(row),
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />

          <Spacer.Horizontal size={16} />

          <Layout.PressableRow
            onClick={() => {
              setSelectedCategoryKey('');
              setShowAddCategoryModal(true);
            }}
            bg="brandPrimaryXLight"
            border={[1, 'solid', 'brandPrimary']}
            py={4}
            px
            radius={100}
            align
          >
            <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
            <Spacer.Horizontal size={8} />
            <OpenSans.Pressable>{getTranslation('Add Category')}</OpenSans.Pressable>
          </Layout.PressableRow>
        </Layout.Row>
      );
    },
  });

  function displayCategoryEditModal(row: MRT_Row<Category>) {
    setSelectedCategoryKey(row.original.key);
    setShowAddCategoryModal(true);
  }

  return (
    <Layout.Column>
      <Spacer.Vertical size={24} />

      <Spacer.Vertical
        size={12}
        style={{
          borderTop: `1px solid ${theme.colors.inputBorder}`,
        }}
      />

      <MaterialReactTable table={table} />

      <Modal showModal={showAddCategoryModal} setShowModal={setShowAddCategoryModal}>
        <UpsertCategory
          onDoneUpsertCategory={() => setShowAddCategoryModal(false)}
          onDiscard={() => setShowAddCategoryModal(false)}
          selectedCategoryKey={selectedCategoryKey}
        />
      </Modal>
    </Layout.Column>
  );
};
