import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ScrollView } from 'react-native';

import { auth } from '../../../firebase/app';
import { OnboardingState } from '../../../pages/ReceiptScanningOnboarding/ReceiptScannerOnboarding';
import { LabelTextField } from '../../LabelTextField.component';
import { EmailSchema } from '../../SignUp.form';

export const Signup: React.FC<{
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
  onboardingState: OnboardingState;
}> = ({ setEmail, setPassword, onboardingState }) => {
  const [emailFocused, setEmailFocused] = React.useState<boolean>(false);
  const [dirtyEmail, setDirtyEmail] = React.useState<boolean>(false);

  const [user] = useAuthState(auth);

  const userCompletedSignUp = React.useMemo(() => {
    setEmail(user?.email ?? '');
    return !user?.isAnonymous;
  }, [user?.isAnonymous]);

  const [passwordFocused, setPasswordFocused] = React.useState<boolean>(false);
  const [dirtyPassword, setDirtyPassword] = React.useState<boolean>(false);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  const emailError = React.useMemo(() => {
    if (!dirtyEmail) {
      return undefined;
    }

    if (!onboardingState.email) {
      return 'Email is required';
    } else if (!EmailSchema.safeParse(onboardingState.email).success) {
      return 'Invalid email';
    } else if (onboardingState.emailError) {
      return onboardingState.emailError;
    }
  }, [onboardingState.email, onboardingState.emailError]);

  const passwordError = React.useMemo(() => {
    if (!dirtyPassword) {
      return undefined;
    }

    if (!onboardingState.password) {
      return 'Password is required';
    } else if ((onboardingState.password?.length ?? 0) < 8) {
      return 'Password must be longer than 8 characters';
    }
  }, [onboardingState.password]);

  return (
    <Layout.Column style={{ height: '100%' }}>
      <ScrollView>
        <OpenSans.Primary size="xl-28" weight="bold-700">
          {getTranslation('Never lose another receipt')}
        </OpenSans.Primary>

        <Spacer.Vertical size={4} />

        <OpenSans.Secondary>
          {getTranslation(
            'Create an account to protect your data. Receipts are automatically backed up to the cloud.',
          )}
        </OpenSans.Secondary>

        <Spacer.Vertical size={32} />

        <LabelTextField
          label={getTranslation('Email')}
          active={emailFocused && !userCompletedSignUp}
          disabled={userCompletedSignUp}
          error={!!emailError}
          errorMessage={emailError}
        >
          <Layout.Row align py={8}>
            <Icon
              name="mail-outline"
              size={24}
              color={emailFocused ? theme.colors.brandPrimary : theme.colors.primary}
            />

            <Spacer.Horizontal />

            <OpenSans.Input
              name="email"
              value={onboardingState.email}
              disabled={userCompletedSignUp}
              type="text"
              min="0"
              step="0.01"
              weight="light-300"
              placeholder="username@email.com"
              grow
              style={{ outline: 'none', width: 500 }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDirtyEmail(true);
                setEmail(event.target.value);
              }}
              enterKeyHint="next"
              inputMode="email"
              autoFocus
              autoComplete="off"
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  passwordRef.current?.focus();
                }
              }}
              onFocus={() => setEmailFocused(true)}
              onBlur={() => setEmailFocused(false)}
            />
          </Layout.Row>
        </LabelTextField>

        <Spacer.Vertical size={16} />

        <LabelTextField
          label={getTranslation('Password')}
          active={passwordFocused && !userCompletedSignUp}
          disabled={userCompletedSignUp}
          error={!!passwordError}
          errorMessage={passwordError}
        >
          <Layout.Row align py={8}>
            <Icon
              name="lock-closed-outline"
              size={24}
              color={passwordFocused ? theme.colors.brandPrimary : theme.colors.primary}
            />

            <Spacer.Horizontal />

            <OpenSans.Input
              name="password"
              value={onboardingState.password}
              disabled={userCompletedSignUp}
              type="password"
              min="0"
              step="0.01"
              weight="light-300"
              placeholder="***********"
              grow
              style={{ outline: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDirtyPassword(true);
                setPassword(event.target.value);
              }}
              autoComplete="off"
              ref={passwordRef}
              enterKeyHint="done"
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  event.currentTarget.blur();
                }
              }}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
            />
          </Layout.Row>
        </LabelTextField>
      </ScrollView>

      <Layout.Column center>
        <p
          style={{
            maxWidth: 350,
            flex: 'wrap',
            textAlign: 'center',
            color: theme.colors.secondary,
            fontSize: 12,
          }}
        >
          {getTranslation("By creating an account you are agreeing to Easy Expense's ")}
          <a
            href="https://www.easy-expense.com/terms-of-service.html"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Terms of Service ')}
          </a>
          {getTranslation('and') + ' '}
          <a
            href="https://www.easy-expense.com/privacy-policy.html"
            style={{ color: theme.colors.secondary }}
          >
            {getTranslation('Privacy Policy')}
          </a>
        </p>
      </Layout.Column>
    </Layout.Column>
  );
};
