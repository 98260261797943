import { entityFields } from '@easy-expense/data-firestore-shared';
import { ReportWithDataSchema } from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import cuid from 'cuid';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { ReportName } from './Details/ReportName.component';
import { ReportMaterialTable } from './ReportMaterialTable.component';
import { auth } from '../../firebase/app';
import { Button } from '../Button.components';
import { InsertDropdown } from '../Shared/Dropdown/InsertableDropdown.component';
import { Modal } from '../Shared/Modal.component';

export const AddNewReportModal: React.FC<{
  show: boolean;
  name?: string;
  setShowModal: (show: boolean) => void;
  createNewReportCallback?: (reportkey?: string) => void;
}> = ({ name, show, setShowModal, createNewReportCallback }) => {
  function createNewReport() {
    if (!user) {
      return;
    }
    let clientKeyToUse: string | null = clientKey;
    if (clientName) {
      clientKeyToUse =
        Data.clients.create({
          value: {
            name: clientName,
          },
        }) ?? null;
    } else {
      clientKeyToUse = null;
    }

    const newReportWithData = ReportWithDataSchema.safeParse({
      ...entityFields('create', user?.uid),
      name: reportName,
      status: 'Unsent',
      expenses: [],
      incomes: [],
      trips: [],
      key: cuid(),
      tripTotal: 0,
      expenseTotal: 0,
      incomeTotal: 0,
      total: 0,
      reportExpenses: [],
      reportIncomes: [],
      reportTrips: [],
      canEdit: true,
      client: clientKeyToUse,
    });

    if (!newReportWithData.success) {
      setErrors((errors) => ({ ...errors, ...newReportWithData.error.formErrors.fieldErrors }));
      return;
    }

    const key = Data.reports.create({ ...newReportWithData.data });

    if (createNewReportCallback) {
      createNewReportCallback(key);
    }
  }

  const [user] = useAuthState(auth);
  const clients = Data.clients.use();
  const [clientKey, setClientKey] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [reportName, setReportName] = React.useState(name);

  React.useEffect(() => {
    setReportName(name);
  }, [name]);

  const [errors, setErrors] = React.useState<{ [k: string]: string[] }>();

  return (
    <Modal
      showModal={show}
      setShowModal={setShowModal}
      footer={
        <Layout.Row
          style={{
            borderTop: `2px solid ${theme.colors.grayXLight}`,
          }}
          px={32}
          py={24}
          justify="flex-end"
        >
          <Button.Primary onClick={() => createNewReport()} radius={50}>
            <OpenSans.Custom
              size={15}
              weight="bold-700"
              style={{ color: theme.colors.buttonWhite }}
            >
              {getTranslation('Create Report')}
            </OpenSans.Custom>
            <Icon
              size={15}
              color={theme.colors.buttonWhite}
              style={{ paddingLeft: 10 }}
              name="chevron-forward"
            />
          </Button.Primary>
        </Layout.Row>
      }
    >
      <Layout.Column style={{ width: 400 }} px={24}>
        <OpenSans.Primary size={24} weight="bold-700">
          {getTranslation('New Report')}
        </OpenSans.Primary>
        <Spacer.Vertical size={16} />

        <ReportName
          name={reportName}
          onChange={(name) => {
            setReportName(name);
          }}
          autoFocus
        />

        <Spacer.Vertical size={16} />

        <InsertDropdown
          header={getTranslation('Client (optional)')}
          placeholder={getTranslation('Client Name')}
          selectedKey={clientKey}
          autoCompleteValues={clients}
          onSelect={(clientName) => {
            if (clientName?.key) {
              setClientKey(clientName.key);
            }
            setClientName(clientName?.value.name || '');
          }}
          allowCreateNew
          iconicIcon="person-circle-outline"
          error={!!errors?.client}
        />

        <Spacer.Vertical size={16} />
      </Layout.Column>
    </Modal>
  );
};

export const ReportList: React.FC<{
  showDownload?: boolean;
}> = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const newReportName = 'Report #' + (Data.reports.getSize() + 1);

  return (
    <Layout.Column style={{ width: '100%', height: '100%' }}>
      <Layout.Row align>
        <OpenSans.Primary size={32} weight="bold-700">
          {getTranslation('Reports')}
        </OpenSans.Primary>
        <Spacer.Horizontal size={32} />

        <Layout.PressableRow
          onClick={() => setShowModal(true)}
          bg="brandPrimaryXLight"
          border={[1, 'solid', 'brandPrimary']}
          py={4}
          px
          radius={100}
          align
        >
          <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
          <Spacer.Horizontal size={8} />
          <OpenSans.Pressable>{getTranslation('Add New Report')}</OpenSans.Pressable>
        </Layout.PressableRow>
      </Layout.Row>

      <Spacer.Vertical size={24} />

      <AddNewReportModal
        show={showModal}
        name={newReportName}
        setShowModal={setShowModal}
        createNewReportCallback={(key?: string) => {
          navigate(`/report/${key}`);
        }}
      />

      <ReportMaterialTable />
    </Layout.Column>
  );
};
