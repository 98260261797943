import { Analytics } from '@easy-expense/analytics-client';
import { WebEnv } from '@easy-expense/env-mobile';
import {
  devDripDiscount,
  devDripPrice,
  productionDripDiscount,
  productionDripPrice,
} from '@easy-expense/utils-shared';
import { Environments, initializePaddle, Paddle as PaddleInstance } from '@paddle/paddle-js';
import React from 'react';

import { paddleClientTokenProd, paddleClientTokenSandbox } from '../../lib/Paddle';

type DripCheckoutProps = {
  email: string;
  dripEmailVersion?: string;
  ref?: React.Ref<PaddleCheckoutRef>;
};

export type PaddleCheckoutRef = {
  onCheckout: () => void;
};

export const DripCheckout = React.forwardRef<PaddleCheckoutRef, DripCheckoutProps>((props, ref) => {
  const { email, dripEmailVersion } = props;
  const [paddle, setPaddle] = React.useState<PaddleInstance>();
  const [successUrl, setSuccessUrl] = React.useState<string>(
    `https://${window.location.host}/login`,
  );
  const price = WebEnv.buildType === 'production' ? productionDripPrice : devDripPrice;
  const discount = WebEnv.buildType === 'production' ? productionDripDiscount : devDripDiscount;

  React.useEffect(() => {
    let paddleEnv: Environments = 'production';
    let paddleClientToken = paddleClientTokenProd;
    if (WebEnv.buildType !== 'production') {
      console.log('using sandbox paddle');
      paddleEnv = 'sandbox';
      paddleClientToken = paddleClientTokenSandbox;
    }
    if (process.env.NODE_ENV === 'development') {
      setSuccessUrl('http://localhost:19006/login');
    }

    initializePaddle({
      environment: paddleEnv,
      token: paddleClientToken,
    })
      .then((paddleInstance: PaddleInstance | undefined) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      })
      .catch((error) => {
        console.error('ERROR with paddle: ', error);
      });
  }, []);

  React.useEffect(() => {
    if (paddle) {
      startPaddleCheckout();
      Analytics.track('drip_checkout_started', { email, dripEmailVersion });
    }
  }, [paddle]);

  function startPaddleCheckout() {
    if (!paddle) {
      alert('Something went wrong');
      return;
    }
    paddle.Checkout.open({
      items: [{ quantity: 1, priceId: price }],
      settings: { successUrl, showAddDiscounts: false, allowLogout: false },
      customer: {
        email,
      },
      discountId: discount,
      customData: { dripEmailVersion },
    });
  }

  React.useImperativeHandle(ref, () => ({
    onCheckout: () => {
      startPaddleCheckout();
    },
  }));

  return null;
});
