import { generatePublicOrgToken, sendReconciledStatements } from '@easy-expense/auth-client';
import { useWorkspaceStore, useWorkspaceKeysStore } from '@easy-expense/data-firestore-client';
import { Expense } from '@easy-expense/data-schema-v2';
import { WebEnv } from '@easy-expense/env-mobile';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import Papa from 'papaparse';
import React from 'react';
import { utils as xlsxUtils, write } from 'xlsx';

import { FailedStatementParsingModal } from './FailedStatementParsingModal.component';
import { ReconcileModal } from './ReconcileModal.component';
import LoadingSpinner from '../LoadingSpinner.component';

const INTERNAL_HEADERS = [
  'Vendor*',
  'Category*',
  'Payment Method*',
  'Reports*',
  'Description*',
  'Created By*',
  'Expense URL*',
  'Attachments*',
];

type ReconciledStatementRow = {
  'Vendor*'?: string;
  'Category*'?: string;
  'Payment Method*'?: string;
  'Reports*'?: string;
  'Description*'?: string;
  'Created By*'?: string;
  'Expense URL*'?: string;
  'Attachments*'?: string;
} & object;

export const ReconcileFileUpload: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [headers, setHeaders] = React.useState<string[]>([]);
  const [dateHeader, setDateHeader] = React.useState('');
  const [totalHeader, setTotalHeader] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [showFailedParseModal, setShowFailedParseModal] = React.useState(false);
  const [statementFile, setStatementFile] = React.useState<File | null>(null);
  const workspaceMembers = useWorkspaceStore((s) => s.workspaceMembers);
  const [token, setToken] = React.useState('');
  const { organizationID, workspaceID } = useWorkspaceKeysStore((s) => ({
    workspaceID: s.currentWorkspaceKey,
    organizationID: s.currentOrganizationKey,
  }));

  React.useEffect(() => {
    async function getToken() {
      if (organizationID && workspaceID) {
        const tokenResponse = await generatePublicOrgToken()({
          organizationID,
          workspaceID,
        });
        setToken(encodeURIComponent(tokenResponse.data));
      }
    }
    if (!token) {
      getToken();
    }
  }, [organizationID, workspaceID, token]);

  async function onUpload(files?: FileList | null) {
    setIsLoading(true);
    if (!files || files.length === 0) {
      return null;
    }

    const file = files[0];

    if (!file || (file.type !== 'text/csv' && !file.name.endsWith('.csv'))) {
      alert(getTranslation('Please upload a CSV file'));
      return;
    }

    setStatementFile(file);
    Papa.parse(file, {
      header: false, // Don't transform into objects
      // preview: 1, // Read only first row
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          alert(getTranslation('Error parsing credit card statement'));
          console.error('ERROR parsing CSV ', results.errors);
          return;
        }

        // Get headers from first row
        const csvHeaders = results.data[0] as string[];

        setHeaders(csvHeaders);
        setShowModal(true);
        setIsLoading(false);
      },
      error: (error) => {
        alert(getTranslation('Error parsing credit card statement'));
        console.error('ERROR parsing CSV error:', error);
      },
    });
  }

  /** returns true if dates are same or close enough
   * */
  function compareStatementDateWithExpenseDate(
    expense: Expense,
    statementRow: DoNotFixMeIAmAny,
  ): boolean {
    const formattedStatementDate = new Date(statementRow[dateHeader]).toLocaleDateString('en-CA');
    return expense.date === formattedStatementDate;
  }

  async function onReconcile() {
    if (!statementFile) {
      console.error('Error no statement file');
      return;
    }
    Papa.parse(statementFile as File, {
      header: true,
      skipEmptyLines: true,
      error: (error) => {
        alert(getTranslation('Error parsing credit card statement'));
        console.error('ERROR parsing CSV error:', error);
      },
      complete: async (results) => {
        setShowModal(false);
        setIsLoading(true);

        const hasInvalidDates = results.data.some((s: DoNotFixMeIAmAny) =>
          isNaN(new Date(s[dateHeader] as string).getDate()),
        );
        const hasInvalidTotals = results.data.some((s: DoNotFixMeIAmAny) =>
          isNaN(Number(s[totalHeader])),
        );

        if (hasInvalidTotals || hasInvalidDates) {
          setShowFailedParseModal(true);
          setIsLoading(false);
          return;
        }

        await reconcileStatement(results.data);
        setIsLoading(false);
      },
    });
  }

  function getExpenses(statementData: DoNotFixMeIAmAny[]) {
    const dates = statementData.map((s) => new Date(s[dateHeader]).toLocaleDateString('en-CA'));
    const allExpenses = Data.expenses.get();
    dates.sort();

    const minDate = dates[0] ?? '';
    const maxDate = dates[dates.length - 1] ?? '';
    if (!dates || !dates[0]) {
      return allExpenses;
    }

    return allExpenses.filter((expense) => expense.date <= maxDate && expense.date >= minDate);
  }

  async function reconcileStatement(statementData: DoNotFixMeIAmAny[]) {
    const matchedExpenseKeys: string[] = [];
    let reconciledStatements: ReconciledStatementRow[] = [];
    const unreconciledStatements: DoNotFixMeIAmAny[] = [];

    const expenses = getExpenses(statementData);
    for (const statementRow of statementData) {
      const matchedExpense = expenses.find((expense) => {
        if (
          compareStatementDateWithExpenseDate(expense, statementRow) &&
          expense.total.toString() === statementRow[totalHeader]
        ) {
          matchedExpenseKeys.push(expense.key);
          return true;
        }
      });

      if (matchedExpense) {
        const vendor = Data.vendors.getByKey(matchedExpense?.vendor)?.value.name;
        const category = Data.expenseCategories.getByKey(matchedExpense?.category)?.value.name;
        const paymentMethod = Data.paymentMethods.getByKey(matchedExpense?.paymentMethod)?.value
          .name;
        const allReports = Data.reports.get();
        const reports = allReports
          .filter((r) => r.expenses.includes(matchedExpense?.key ?? ''))
          .map((r) => r.name)
          .join(', ');
        const workspaceMember = workspaceMembers[matchedExpense.createdBy];
        const createdByName = workspaceMember?.displayName ?? 'Anonymous';
        const receiptsURL = getReceiptsURL(matchedExpense);
        const receiptMatchStatus = receiptsURL ? 'Reconciled' : 'Missing Receipt';

        reconciledStatements.push({
          Status: receiptMatchStatus,
          'Vendor*': vendor,
          'Category*': category,
          'Payment Method*': paymentMethod,
          'Reports*': reports,
          'Description*': matchedExpense?.desc,
          'Created By*': createdByName,
          'Expense URL*': `${window.location.origin}/expense/${matchedExpense?.key}`,
          'Attachments*': receiptsURL,
          ...statementRow,
        });
      } else {
        reconciledStatements.push({
          Status: 'Missing Receipt',
          ...statementRow,
        });
        unreconciledStatements.push({
          ...statementRow,
        });
      }
    }

    const { unmatchedExpenses, reconciledStatements: splitUnreconciledStatments } =
      await handleSplitExpenses({
        expenses,
        matchedExpenseKeys,
        unreconciledStatements,
      });

    reconciledStatements = [...reconciledStatements, ...splitUnreconciledStatments];

    await sendStatementFileToSupport(
      formatUnmatchedExpenses(unmatchedExpenses),
      'unnmatched-expenses.xlsx',
    );
    await sendStatementFileToSupport(reconciledStatements, 'reconciled-statement.xlsx', [
      'Status',
      ...headers,
      ...INTERNAL_HEADERS,
    ]);
    await sendStatementFileToSupport(statementData, 'original-statement.csv');
    alert(
      getTranslation(
        "CSV file has been received. We'll email you the most recent reconciliation report within 48 hours.",
      ),
    );
  }

  function formatUnmatchedExpenses(unmatchedExpenses: Expense[]): ReconciledStatementRow[] {
    const formattedUnmatchedExpenses = unmatchedExpenses.map((expense) => {
      const vendor = Data.vendors.getByKey(expense?.vendor);
      const paymentMethod = Data.paymentMethods.getByKey(expense?.paymentMethod)?.value.name;
      const category = Data.expenseCategories.getByKey(expense?.category)?.value.name;
      const allReports = Data.reports.get();
      const reports = allReports
        .filter((r) => r.expenses.includes(expense?.key ?? ''))
        .map((r) => r.name)
        .join(', ');
      const workspaceMember = workspaceMembers[expense.createdBy];
      const createdByName = workspaceMember?.displayName ?? 'Anonymous';

      return {
        Total: expense.total,
        Date: expense.date,
        Vendor: vendor?.value.name,
        'Created By': createdByName,
        'Payment Method': paymentMethod,
        Reports: reports,
        Category: category,
        Description: expense.desc ?? '',
        'Expense URL': `${window.location.origin}/expense/${expense?.key}`,
        Attachments: getReceiptsURL(expense),
      };
    });
    return formattedUnmatchedExpenses;
  }

  async function handleSplitExpenses(params: {
    expenses: Expense[];
    matchedExpenseKeys: string[];
    unreconciledStatements: DoNotFixMeIAmAny[];
  }) {
    let unmatchedExpenses = params.expenses.filter(
      (expense) => !params.matchedExpenseKeys.includes(expense.key),
    );
    const unreconciledStatements = [...params.unreconciledStatements];
    const reconciledStatements: DoNotFixMeIAmAny[] = [];
    const unmatchedExpensesMap: Map<string, Expense[]> = new Map();

    unmatchedExpenses.forEach((expense) => {
      const dateVendor = `${expense.date}${expense.vendor}`;
      const unmatchedExpensesByDateVendor = unmatchedExpensesMap.get(dateVendor) ?? [];
      unmatchedExpensesMap.set(dateVendor, [...unmatchedExpensesByDateVendor, expense]);
    });

    unmatchedExpensesMap.forEach((unmatchedExpensesByDateVendor) => {
      if (unmatchedExpensesByDateVendor.length <= 1) {
        return;
      }
      const total = unmatchedExpensesByDateVendor.reduce((acc, exp) => ({
        ...exp,
        total: acc.total + exp.total,
      })).total;

      const unreconciledStatement = unreconciledStatements.find((unreconciledStatementRow) =>
        unmatchedExpensesByDateVendor.some((exp) => {
          if (
            compareStatementDateWithExpenseDate(exp, unreconciledStatementRow) &&
            total === Number(unreconciledStatementRow[totalHeader])
          ) {
            unmatchedExpenses = unmatchedExpenses.filter(
              (unmatchedExp) =>
                !(exp.vendor === unmatchedExp.vendor && exp.date === unmatchedExp.date),
            );
            return true;
          }
        }),
      );

      const unmatchedExpense = unmatchedExpensesByDateVendor.pop();
      const vendorKey = unmatchedExpense?.vendor;
      const categoryKey = unmatchedExpense?.category;
      const paymentMethodKey = unmatchedExpense?.paymentMethod;
      const allReports = Data.reports.get();
      const reports = allReports
        .filter((r) => r.expenses.includes(unmatchedExpense?.key ?? ''))
        .map((r) => r.name)
        .join(', ');
      const workspaceMember = workspaceMembers[unmatchedExpense?.createdBy ?? ''];
      const createdByName = workspaceMember?.displayName ?? 'Anonymous';

      if (unreconciledStatement) {
        reconciledStatements.push({
          Status: 'Reconciled',
          'Vendor*': Data.vendors.getByKey(vendorKey)?.value.name ?? '',
          'Category*': Data.expenseCategories.getByKey(categoryKey)?.value.name ?? '',
          'Payment Method*': Data.paymentMethods.getByKey(paymentMethodKey)?.value.name ?? '',
          'Reports*': reports,
          'Description*': unmatchedExpense?.desc,
          'Created By*': createdByName,
          'Expense URL*': `${window.location.origin}/expense/${unmatchedExpense?.key}`,
          'Attachments*': getReceiptsURL(unmatchedExpense),
          ...unreconciledStatement,
        });
      }
    });

    return { unmatchedExpenses, reconciledStatements };
  }

  /** Converts object into xlsx file and sends it by email to Hung.
   * To hardcode the order of the columns pass in the `headers` param.
   * For more details see https://docs.sheetjs.com/docs/api/utilities/array#array-of-objects-input
   * */
  async function sendStatementFileToSupport(jsonData: any[], fileName: string, header?: string[]) {
    const wb = xlsxUtils.book_new();
    const ws = xlsxUtils.json_to_sheet(jsonData, { header });

    if (header) {
      xlsxUtils.sheet_add_aoa(ws, [header]);
    }

    xlsxUtils.book_append_sheet(wb, ws, 'Expenses');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const base64String = await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          // Remove the data URL prefix (e.g., "data:application/vnd.ms-excel;base64,")
          const base64 = reader.result.split(',')[1] ?? '';
          resolve(base64);
        } else {
          reject(new Error('Failed to convert file to base64'));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(blob);
    });

    await sendReconciledStatements()({
      fileName,
      fileBuffer: base64String,
      organizationID,
      workspaceID,
    });

    if (
      window.location.host === 'localhost:19006' ||
      window.location.host === 'https://beta-staging.easy-expense.com/'
    ) {
      // download files when testing but real users should get the reconciled statement from Hung
      const link = document.createElement('a');

      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    }
  }

  function getReceiptsURL(expense: Expense | undefined) {
    if (!expense || !expense?.key || !expense?.receipts.length) {
      return '';
    }
    return `${WebEnv.firebase.cloudPrefix}/reconciliation-expenseReceipts/${expense.key}?token=${token}`;
  }

  if (isLoading) {
    return (
      <Layout.Column center grow>
        <Layout.Row center>
          <LoadingSpinner />
        </Layout.Row>
      </Layout.Column>
    );
  }

  return (
    <Layout.Column
      grow
      center
      onDrop={(event: DragEvent) => {
        event.preventDefault();
        onUpload(event?.dataTransfer?.files);
      }}
      onDragOver={(event: DragEvent) => event.preventDefault()}
    >
      <Layout.PressableColumn bg="transparent">
        <label
          htmlFor="file"
          style={{
            borderStyle: 'dashed',
            borderWidth: 1,
            borderRadius: 6,
            borderColor: theme.colors.brandPrimary,
            backgroundColor: theme.colors.brandPrimaryXLight,
            margin: 32,
            padding: 32,
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <Layout.Column center style={{ justifyContent: 'space-between' }}>
            <Icon name="receipt-outline" size={23} color={theme.colors.brandPrimary} />
            <Spacer.Vertical size={12} />
            <OpenSans.Pressable weight="bold-700">
              {getTranslation('Drag & drop credit card statement')}
            </OpenSans.Pressable>
            <Spacer.Vertical size={4} />
            <OpenSans.Pressable size="xs-12">
              {getTranslation('We will reconcile automatically')}
            </OpenSans.Pressable>
            <input
              type="file"
              id="file"
              accept=".csv"
              onChange={(event) => {
                onUpload(event.target.files);
                event.target.value = ''; // so that you can re-upload the same file
              }}
              style={{ display: 'none' }}
            />
          </Layout.Column>
        </label>
      </Layout.PressableColumn>

      <ReconcileModal
        showModal={showModal}
        setShowModal={setShowModal}
        onReconcile={onReconcile}
        dateHeader={dateHeader}
        setDateHeader={setDateHeader}
        totalHeader={totalHeader}
        setTotalHeader={setTotalHeader}
        headers={headers}
      />
      <FailedStatementParsingModal
        showModal={showFailedParseModal}
        setShowModal={setShowFailedParseModal}
      />
    </Layout.Column>
  );
};
