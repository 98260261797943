import Data from '@easy-expense/frontend-data-layer';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { Dimensions } from 'react-native';

import { OnboardingExpense } from './ReceiptScanner';
import { CurrencyField } from '../../components/Expense/Edit/CurrencyInput.component';
import { DateField } from '../../components/Expense/Edit/DateField.component';
import { NotesField } from '../../components/Expense/Edit/NotesField.component';
import { InsertDropdown } from '../../components/Shared/Dropdown/InsertableDropdown.component';
import { SearchDropdown } from '../../components/Shared/Dropdown/SearchDropDown.component';

const SCHEDULE_C_DEDUCTIONS: Record<
  number | string,
  { name: string; deduction_percentage: number }
> = {
  '1a': { name: 'Gross Receipts or Sales', deduction_percentage: 1.0 },
  '1b': { name: 'Returns and Allowances', deduction_percentage: 1.0 },
  '1c': { name: 'Net Sales', deduction_percentage: 1.0 },
  '2': { name: 'Car and Truck Expenses', deduction_percentage: 1.0 },
  '3': { name: 'Commissions and Fees', deduction_percentage: 1.0 },
  '4': { name: 'Contract Labor', deduction_percentage: 1.0 },
  '5': { name: 'Depreciation and Section 179 Deduction', deduction_percentage: 1.0 },
  '5a': { name: 'Depreciation (Form 4562)', deduction_percentage: 1.0 },
  '5b': { name: 'Section 179 Deduction', deduction_percentage: 1.0 },
  '6': { name: 'Employee Benefit Programs', deduction_percentage: 1.0 },
  '7': { name: 'Insurance (other than health)', deduction_percentage: 1.0 },
  '8': { name: 'Interest (Mortgage)', deduction_percentage: 1.0 },
  '9': { name: 'Interest (Other)', deduction_percentage: 1.0 },
  '10': { name: 'Legal and Professional Services', deduction_percentage: 1.0 },
  '11': { name: 'Office Expenses', deduction_percentage: 1.0 },
  '12': { name: 'Pension and Profit-Sharing Plans', deduction_percentage: 1.0 },
  '13': { name: 'Rent or Lease (Vehicles, Machinery, Equipment)', deduction_percentage: 1.0 },
  '14': { name: 'Rent or Lease (Other Business Property)', deduction_percentage: 1.0 },
  '15': { name: 'Repairs and Maintenance', deduction_percentage: 1.0 },
  '16': { name: 'Supplies', deduction_percentage: 1.0 },
  '17': { name: 'Taxes and Licenses', deduction_percentage: 1.0 },
  '18': { name: 'Travel', deduction_percentage: 1.0 },
  '18a': { name: 'Lodging', deduction_percentage: 1.0 },
  '18b': { name: 'Transportation', deduction_percentage: 1.0 },
  '19': { name: 'Business Meals', deduction_percentage: 0.5 },
  '20': { name: 'Utilities', deduction_percentage: 1.0 },
  '21': { name: 'Wages', deduction_percentage: 1.0 },
  '21a': { name: 'Salaries and Wages', deduction_percentage: 1.0 },
  '21b': { name: 'Payroll Taxes', deduction_percentage: 1.0 },
  '22': { name: 'Other Expenses', deduction_percentage: 1.0 },
};

const Header: React.FC<{ expense: OnboardingExpense; hide: boolean }> = ({ expense, hide }) => {
  const { formatCurrency } = useIntlStore();

  const scheduleC = SCHEDULE_C_DEDUCTIONS[expense.line_number ?? 0];
  const deductionPercentage = scheduleC?.deduction_percentage ?? 1;

  const formattedTotal = expense.total
    ? formatCurrency(expense.total * deductionPercentage)
    : formatCurrency(0);

  if (hide) {
    return null;
  }

  if (scheduleC) {
    return (
      <Layout.Column>
        <Layout.Row grow>
          <OpenSans.Primary size={24} weight="bold-700" center>
            {`This is a ${formattedTotal} tax deduction!`}
          </OpenSans.Primary>
        </Layout.Row>

        <Spacer.Vertical size={32} />

        <Layout.Row
          style={{ width: '100%' }}
          bg="successXLight"
          border={[2, 'solid', 'successDark']}
          px
          py
          radius
          align
        >
          <Icon name="shield-checkmark" size={32} color={theme.colors.successDark} />
          <Spacer.Horizontal size={16} />
          <Layout.Column>
            <OpenSans.Primary weight="bold-700">{`Schedule C, Line ${expense.line_number}`}</OpenSans.Primary>
            <Spacer.Vertical size={2} />
            <OpenSans.Primary size={14}>
              {`The IRS allows businesses to deduct ${deductionPercentage * 100}% of ${expense.category.name} related to work under Line ${expense.line_number}.`}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>
      </Layout.Column>
    );
  }

  return (
    <Layout.Row
      style={{ width: '100%' }}
      bg="destructiveXLight"
      border={[2, 'solid', 'destructiveDark']}
      px
      py
      radius
      align
    >
      <Icon name="warning" size={32} color={theme.colors.destructiveDark} />
      <Spacer.Horizontal size={16} />
      <Layout.Column>
        <OpenSans.Primary weight="bold-700">
          {getTranslation('Error Scanning Document')}
        </OpenSans.Primary>
        <Spacer.Vertical size={2} />
        <OpenSans.Primary size={14}>
          {getTranslation('Ensure the document is clear and that the text is legible.')}
        </OpenSans.Primary>
      </Layout.Column>
    </Layout.Row>
  );
};

export const ScannedReceiptResults = ({
  result,
  countryCode,
}: {
  result: OnboardingExpense;
  countryCode: string;
}) => {
  const [expense, setExpense] = React.useState<OnboardingExpense & { desc?: string }>(result);
  const [category, setCategory] = React.useState<string>();

  const categories = Data.expenseCategories.use();

  const hidePreview = Dimensions.get('window').width < 800;

  return (
    <Layout.Row style={{ height: '100%', overflow: 'hidden' }} justify>
      {!hidePreview ? (
        <Layout.Column grow px={24} py align>
          <Layout.Column
            radius={8}
            style={{ width: '100%', height: '100%', maxWidth: 500, position: 'relative' }}
          >
            <img
              src={expense.image_url}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
                borderRadius: 6,
              }}
            />
          </Layout.Column>
        </Layout.Column>
      ) : null}

      <Layout.Column style={{ height: '100%', overflow: 'scroll' }} grow px>
        <Layout.Column style={{ maxWidth: 500 }}>
          <Header expense={expense} hide={!!category || countryCode !== 'US'} />

          <Spacer.Vertical size={32} />

          <InsertDropdown
            header={getTranslation('Vendor')}
            placeholder={getTranslation('Vendor Name')}
            selectedKey={'1'}
            autoCompleteValues={[
              {
                key: '1',
                value: { name: expense.vendor },
              },
            ]}
            error={false}
            onSelect={(v) => {
              console.log('here');
              setExpense((expense) => ({ ...expense, vendor: v?.value.name ?? '' }));
            }}
            iconicIcon="storefront-outline"
          />

          <Spacer.Vertical size={16} />

          <SearchDropdown
            header={getTranslation('Category')}
            placeholder={getTranslation('Category Name')}
            selectedKey={category ?? '@'}
            autoCompleteValues={
              category
                ? categories
                : [
                    {
                      key: '@',
                      value: { name: expense.category.name, icon: expense.category.emoji },
                    },
                    ...categories,
                  ]
            }
            onSelect={(c) => {
              setCategory(c?.key);
              setExpense((expense) => ({
                ...expense,
                category: {
                  name: c?.value.name ?? '',
                  emoji: c?.value.icon ?? '',
                },
              }));
            }}
            error={false}
            iconicIcon="pricetags-outline"
          />

          <Spacer.Vertical size={16} />

          {/* FIXME: this icon will be replaced with the workspace's currency icon once that has been added to the onboarding flow */}
          <Layout.Row>
            <Layout.Column grow>
              <CurrencyField
                onChange={(total) => {
                  setExpense((expense) => ({ ...expense, total }));
                }}
                initialValue={expense.total}
                label={
                  getTranslation('Total') +
                  ((expense.total ?? 0) < 0 ? ' (' + getTranslation('refund') + ')' : '')
                }
                allowNegative
              />
            </Layout.Column>

            {expense.tax.map((tax, index) => {
              return (
                <Layout.Column grow style={{ marginLeft: '12px' }}>
                  <CurrencyField
                    onChange={(taxAmount) => {
                      const taxes = expense.tax;
                      taxes[index] = { type: tax.type, amount: taxAmount };
                      setExpense((expense) => ({
                        ...expense,
                        tax: taxes,
                      }));
                    }}
                    initialValue={tax.amount}
                    label={tax.type}
                    allowNegative
                  />
                </Layout.Column>
              );
            })}
          </Layout.Row>

          <Spacer.Vertical size={16} />

          <DateField
            label="Date"
            initialDate={expense.date}
            onChange={(date) => {
              setExpense({ ...expense, date });
            }}
          />

          <Spacer.Vertical size={16} />

          <NotesField
            desc={expense.desc ?? ''}
            onChange={(notes) => setExpense((expense) => ({ ...expense, desc: notes }))}
          />

          <Spacer.Vertical size={16} />

          <Layout.Row align>
            <OpenSans.Custom size="xs-12" color={'secondary'} weight="bold-700">
              {'Receipts'}
            </OpenSans.Custom>
          </Layout.Row>

          <Spacer.Vertical size={6} />

          <Layout.Row align>
            <Layout.Column
              border={[1, 'solid', 'primary']}
              radius={8}
              style={{ width: 82, height: 100, position: 'relative' }}
              bg="white"
            >
              <img
                src={expense.image_url}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: 6,
                }}
              />
            </Layout.Column>
          </Layout.Row>

          <Spacer.Vertical size={32} />
        </Layout.Column>
      </Layout.Column>
    </Layout.Row>
  );
};
