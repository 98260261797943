import { TripClassValue } from '@easy-expense/data-schema-v2';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { darken, lighten } from 'polished';
import React from 'react';

export const TripClassBadge: React.FC<React.PropsWithChildren<{ tripClass?: TripClassValue }>> = ({
  tripClass,
}) => {
  if (!tripClass) {
    return null;
  }

  const tripClassColor = hueToColor(tripClass.color ?? 0);

  return (
    <Layout.Row style={{ width: 200 }}>
      <Layout.Row
        radius={4}
        style={{ backgroundColor: lighten(0.35, `${tripClassColor}`) }}
        py={4}
        px={8}
      >
        <OpenSans.Primary
          size="s-12"
          singleLine={{ maxWidth: '160px' }}
          style={{
            color: darken(0.35, `${tripClassColor}`),
          }}
        >
          {tripClass.icon}&nbsp;&nbsp;{tripClass.name}
        </OpenSans.Primary>
      </Layout.Row>
    </Layout.Row>
  );
};
