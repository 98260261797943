import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

//import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ScrollView } from 'react-native';

import { Circle } from '../../Shape.components';

export const PaddleCheckout: React.FC = () => {
  return (
    <Layout.Column style={{ height: '100%' }}>
      <ScrollView>
        <OpenSans.Primary weight="bold-700" size="xl-28">
          {getTranslation('$500 Guaranteed')}
        </OpenSans.Primary>
        <Spacer.Vertical size={8} />
        <OpenSans.Secondary size={16}>
          {getTranslation(`Save $500 in tax deductions or your money back. No questions asked.`)}
        </OpenSans.Secondary>

        <Spacer.Vertical size={36} />

        <Layout.Row px={16}>
          <Layout.Column align>
            <Circle.Solid circleSize={40} bg="brandSecondaryLight" center>
              <Icon name="checkmark-outline" size={32} iconColor="brandSecondary" />
            </Circle.Solid>
            <Layout.Column style={{ width: 8, height: 40, zIndex: 1 }} bg="brandSecondaryLight" />
          </Layout.Column>

          <Spacer.Horizontal size={16} />

          <Layout.Column grow>
            <OpenSans.Primary
              weight="bold-700"
              style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}
              size="l-20"
              color="brandSecondary"
              allowFontScaling={false}
            >
              {getTranslation('Customize Workspace')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('You successfully set up your workspace')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>

        <Layout.Row px={16}>
          <Layout.Column align>
            <Layout.Column>
              <Circle.Solid
                circleSize={56}
                bg="brandSecondaryXLight"
                center
                style={{ position: 'absolute', top: -8, left: -8, zIndex: 0 }}
              ></Circle.Solid>
              <Circle.Solid circleSize={40} bg="brandSecondaryLight" center>
                <Icon name="lock-open-outline" size={24} />
              </Circle.Solid>
            </Layout.Column>
            <Layout.Column style={{ width: 8, height: 20 }} bg="brandSecondaryLight" />
            <Layout.Column style={{ width: 8, height: 20 }} bg="grayXLight" />
          </Layout.Column>

          <Spacer.Horizontal size={16} />

          <Layout.Column grow>
            <OpenSans.Primary weight="bold-700" size="l-20" allowFontScaling={false}>
              {getTranslation('Today: Get Full Access')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Scan unlimited receipts and find thousands in tax deductions')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>

        <Layout.Row px={16}>
          <Layout.Column align>
            <Circle.Solid circleSize={40} bg="grayXLight" center>
              <Icon name="notifications-outline" size={24} />
            </Circle.Solid>
            <Layout.Column style={{ width: 8, height: 40 }} bg="grayXLight" />
          </Layout.Column>

          <Spacer.Horizontal size={16} />

          <Layout.Column grow>
            <OpenSans.Primary weight="bold-700" size="l-20" allowFontScaling={false}>
              {getTranslation('Day')} {Math.max(7 - 2, 2)}: {getTranslation('Trial Reminder')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('We’ll send you an email + notification.')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>

        <Layout.Row px={16}>
          <Layout.Column align>
            <Circle.Solid circleSize={40} bg="grayXLight" center>
              <Icon name="star-outline" size={24} />
            </Circle.Solid>
          </Layout.Column>

          <Spacer.Horizontal size={16} />

          <Layout.Column grow>
            <OpenSans.Primary weight="bold-700" size="l-20" allowFontScaling={false}>
              {getTranslation('Day')} 7: {getTranslation('Trial Ends')}
            </OpenSans.Primary>
            <OpenSans.Primary size="xs-12">
              {getTranslation('Forget to cancel? Contact us for a full refund.')}
            </OpenSans.Primary>
          </Layout.Column>
        </Layout.Row>
      </ScrollView>

      <Layout.Column center>
        <OpenSans.Primary allowFontScaling={false}>
          {`7 ${getTranslation('day free trial')}, ${getTranslation('then')}`}
        </OpenSans.Primary>
        <Spacer.Vertical size={2} />
        <OpenSans.Primary allowFontScaling={false} weight="bold-700">
          {getTranslation(`$6 per month`)}
        </OpenSans.Primary>
        <Spacer.Vertical size={2} />
        <OpenSans.Primary allowFontScaling={false}>
          {getTranslation(`(billed $75 annually)`)}
        </OpenSans.Primary>
      </Layout.Column>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
