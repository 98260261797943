import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';

import { SurveyRow } from './SurveyRow';
import { LabelTextField } from '../../LabelTextField.component';

export type SearchSurveyOption = {
  title: string;
  emoji?: string;
  subtitle?: string;
  success?: string;
  searchQueries?: string[];
};

export type SearchSurveyStepProps = {
  header: string;
  text: string;
  options: { groupHeader: string; groupOptions: SearchSurveyOption[] }[];
  selectedOptions: string[];
  multiSelect: boolean;
  onOptionSelected: (options: string[]) => void;
};

// remove any groupOption that does not match the query
// remove any groupHeader that does not have any groupOptions
const filterOptions = (
  options: { groupHeader: string; groupOptions: SearchSurveyOption[] }[],
  query: string,
) => {
  console.log(options);
  return options
    .map((group) => {
      const groupOptions = group.groupOptions.filter(
        (option) =>
          option.title.toLowerCase().includes(query.toLowerCase()) ||
          option.searchQueries?.find((q) => q.toLowerCase().includes(query.toLowerCase())),
      );
      return groupOptions.length > 0 ? { ...group, groupOptions } : null;
    })
    .filter((group) => group) as { groupHeader: string; groupOptions: SearchSurveyOption[] }[];
};

export const SearchSurvey: React.FC<SearchSurveyStepProps> = ({
  header,
  text,
  options,
  multiSelect,
  selectedOptions = [],
  onOptionSelected,
}) => {
  const [searchFocused, setSearchFocused] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(options);
  const [surveyResult, setSurveyResult] = React.useState<string[]>(selectedOptions);

  const toggleSurveyResult = (option: SearchSurveyOption) => {
    if (multiSelect) {
      setSurveyResult((prev) => {
        let resultUpdate;
        if (prev.includes(option.title)) {
          resultUpdate = prev.filter((item) => item !== option.title);
        } else {
          resultUpdate = [...prev, option.title];
        }

        onOptionSelected(resultUpdate);
        return resultUpdate;
      });
    } else {
      onOptionSelected([option.title]);
      setSurveyResult([option.title]);
    }
  };

  React.useEffect(() => {
    setFilteredOptions(filterOptions(options, query));
  }, [query]);

  return (
    <Layout.Column>
      <OpenSans.Primary weight="bold-700" size={24}>
        {header}
      </OpenSans.Primary>
      <Spacer.Vertical size={4} />
      <OpenSans.Secondary size={'s-16'}>{text}</OpenSans.Secondary>

      <Spacer.Vertical size={24} />

      <LabelTextField
        label=""
        styles={{ backgroundColor: theme.colors.inputBackground }}
        active={searchFocused}
        icon="search"
      >
        <OpenSans.Input
          name="search"
          value={query}
          type="text"
          weight="bold-700"
          placeholder="Search"
          grow
          size="l-20"
          style={{ outline: 'none', width: '80%', paddingTop: 6, paddingBottom: 6 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
          autoComplete="off"
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
          autoFocus
        />
      </LabelTextField>

      <Spacer.Vertical size={16} />

      {filteredOptions.map((option, index) => {
        return (
          <Layout.Column key={index}>
            <OpenSans.Primary weight="bold-700" size="s-16">
              {option.groupHeader}
            </OpenSans.Primary>
            <Spacer.Vertical size={4} />
            {option.groupOptions.map((option: SearchSurveyOption, index: number) => (
              <SurveyRow
                key={index}
                selected={!!surveyResult.includes(option.title)}
                title={option.title}
                emoji={option.emoji}
                subtitle={option.subtitle}
                onPress={() => {
                  toggleSurveyResult(option);
                }}
              />
            ))}
          </Layout.Column>
        );
      })}
    </Layout.Column>
  );
};
