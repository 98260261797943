import { EntityFilter } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';

import { CurrencyField } from '../Expense/Edit/CurrencyInput.component';

export const ExportTotalFilters: React.FC<{
  entityFilter: EntityFilter;
  setExportFilters: (filters: EntityFilter) => void;
}> = ({ entityFilter, setExportFilters }) => {
  return (
    <Layout.Column>
      <Layout.Row align>
        <OpenSans.Primary size={22} weight="bold-700">
          {getTranslation('Total Filters')}
        </OpenSans.Primary>
      </Layout.Row>
      <Spacer.Vertical size={8} />
      <Layout.Row align>
        <CurrencyField
          key={`total_min`}
          onChange={(total) => {
            let minTotal: number | null = total;

            if (isNaN(total)) {
              minTotal = null;
            }
            setExportFilters({ ...entityFilter, minTotal });
          }}
          updatedValue={entityFilter.minTotal ?? undefined}
          label={getTranslation('At least...')}
          allowNegative
        />
        <Spacer.Horizontal />

        <CurrencyField
          key={`total_max`}
          onChange={(total) => {
            let maxTotal: number | null = total;
            if (isNaN(total)) {
              maxTotal = null;
            }
            setExportFilters({ ...entityFilter, maxTotal });
          }}
          updatedValue={entityFilter.maxTotal ?? undefined}
          label={getTranslation('No more than...')}
          allowNegative
        />
      </Layout.Row>

      <Spacer.Vertical size={12} />
    </Layout.Column>
  );
};
